import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { usePost } from "seed/api";
import { Redirect, useLocation } from "react-router";
import NotFound from "components/utils/NotFound";

function Index({ history }) {
  let { search } = useLocation();
  let query = new URLSearchParams(search);
  const formId = query.get("form_id");
  const userId = query.get("userId");
  if (formId != null)
    localStorage.setItem("formId", formId);
  
  const [callAuth, reqAuth] = usePost("/approvals/" + formId + "/user_auth", {
    onCompleted: (data) => {
      const { verified, approval, status, version } = data;
      
      if (verified) {
        if (approval || status == "REJECTED") {
          history.replace("/now_verified?status=" + status);
        } else {
          version == "2" ?  history.replace("/approval?userId=" + userId) : history.replace("/approvalv1?userId=" + userId) ;
         
        }
      } else {
        history.replace("/no_verify?status=" + verified);
      }
    }
  });

  useEffect(() => {
    callAuth({ userId: userId });
  }, []);
  if(userId == "" || formId == "") return <NotFound />;
  return <div></div>;
}

Index.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Index;
