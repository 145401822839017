import React from "react";
import PropTypes from "prop-types";
import View from "components/form/NowVerified.view";
import { IS_PROD } from "settings";

function NowVerified({
  status,
  onResend,
  inputAssociated,
  inputPartners,
  stage,
}) {
  return (
    <View
      status={status}
      onResend={onResend}
      inputPartners={inputPartners}
      inputAssociated={inputAssociated}
      stage={stage}
      IS_PROD={IS_PROD}
    />
  );
}

NowVerified.propTypes = {};

export default NowVerified;
