import React from "react";
import PropTypes from "prop-types";
import View from "components/auth/LoginAuth.view";
import { useLocation } from "react-router";

function LoginAuth(props) {

  let { search } = useLocation();
  let query = new URLSearchParams(search);
  const status = query.get("status");
  const role = query.get("role");
  const token = query.get("token");
  const id = query.get("id");
  const isCC = query.get("isCC") == "True";

  if (status == "OK"){
    if (!isCC) {
      window.location.href = "/";
    } else {
      sessionStorage.setItem('token', token);
      sessionStorage.setItem('id', id);
      sessionStorage.setItem('role', role);
      sessionStorage.setItem('isCC', isCC);
      window.location.href = "/home";
    }
  } else {
    return <div>Ha ocurrido un error iniciando sesión</div>;
  }

}

LoginAuth.propTypes = {};

export default LoginAuth;