import React from "react";
import PropTypes from "prop-types";
import View from "components/utils/NotFound.view";

function NotFound() {
  return <View />;
}

NotFound.propTypes = {};

export default NotFound;