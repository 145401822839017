import React, { useState } from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { FORM_SCHEMA } from "utils";
import { IS_PROD } from "settings";
import View from "components/partner/QaApprovalCC.view";
import { Loading } from "seed/helpers";
import swal from "sweetalert";
import { useDetail } from "seed/gql";
import { useGet, usePost } from "seed/api";
import NotFound from "components/utils/NotFound";

function QaApproval(props) {

  const { formId, documentId } = props.match.params;
  const [callLoading, setCallLoading] = useState(false);
  const history = useHistory();

  const [queryPermS, setQueryPermS] = useState({ value: "", hidden: false });
  const [formatSignS, setFormatSignS] = useState({ value: "", hidden: false });
  const [isTranslationS, setIsTranslationS] = useState({
    value: "",
    hidden: false,
  });
  const [isComplementS, setIsComplementS] = useState({
    value: "",
    hidden: false,
  });
  const [isSubstitutionS, setIsSubstitutionS] = useState({
    value: "",
    hidden: false,
  });
  const [codFiscalFederationS, setCodFiscalFederationS] = useState({
    value: "",
  });
  const [docReportTypeS, setDocReportTypeS] = useState({
    value: "",
    hidden: false,
  });
  const [art5aS, setArt5aS] = useState({ value: "", hidden: false });
  const [art199S, setArt199S] = useState({ value: "", hidden: false });
  const [desingSchemeS, setDesingSchemeS] = useState({
    value: "",
    hidden: false,
  });
  const [clientReportS, setClientReportS] = useState({
    value: "",
    hidden: false,
  });
  const [art197S, setArt197S] = useState({ value: "", hidden: false });
  const [impetimentLegalS, setImpetimentLegalS] = useState({
    value: "",
    hidden: false,
  });
  const [art197ConstS, setArt197ConstS] = useState({
    value: "",
    hidden: false,
  });
  const [relieveSchemaS, setRelieveSchemaS] = useState({
    value: "",
    hidden: false,
  });
  const [mountSchemaS, setMountSchemaS] = useState({
    value: "",
    hidden: false,
  });
  const [firstComercializationS, setFirstComercializationS] = useState({
    value: "",
    hidden: false,
  });
  const [art201S, setArt201S] = useState({ value: "", hidden: false });
  const [declarationInfoS, setDeclarationInfoS] = useState({
    value: "",
    hidden: false,
  });
  const [otherAsesorsS, setOtherAsesorsS] = useState({
    value: "",
    hidden: false,
  });
  const [relieveAsesorS, setRelieveAsesorS] = useState({
    value: "",
    hidden: false,
  });
  const [reportableGeneralS, setReportableGeneralS] = useState({
    value: "",
    hidden: false,
  });
  const [art197pa6S, setArt197pa6S] = useState({ value: "", hidden: false });
  const [asesorArt197pa6S, setAsesorArt197pa6S] = useState({
    value: "",
    hidden: false,
  });
  const [constRelieveSchemeS, setConstRelieveSchemeS] = useState({
    value: "",
    hidden: false,
  });
  const [eptOrValuationS, setEptOrValuationS] = useState({ value: "", hidden: false })
  const [ccPrevS, setCcPrevS] = useState({ value: "", hidden: false })
  const [eptMov, setEptMov] = useState([{ dateDataBase: "", financeInfo: "", rejectRI: "", listReport: "", transferPricing: "", utilityTransferPricing: "", marginRange: "", marginRangeAdjust: "" }]);
  const [areas, setAreas] = useState([{ area: "" }]);

  const reqForm = useGet(
    "/approvals/" + formId,
    {},
    {
      onCompleted: (data) => {
        const { body } = data;
        const { emittingArea, eptMov } = body;
        if(typeof(emittingArea) =="string") {
          setAreas([{area: emittingArea}]);
        } else {
          setAreas(emittingArea);
        }

        setEptMov(eptMov);
      },
      onError: (error) => {
        console.log(error);
        window.location.href = "/not_found";
      }
    }
  );

  const reqDocument = useDetail(`{
    document { 
      file { url }
      request { ndDocumentId }
    }
  }`, documentId, {
    onError: (error) => {
      console.log(error);
      window.location.href = "/not_found";
    }
  });

  // TODO delete
  const onCompletedSet = () => {
    swal({
      title: "Información correcta",
      icon: "success",
      text: "Se aprobó el contenido",
      buttons: {
        confirm: {
          text: "Ok",
          className: "swal-button swal-button--cancel btn-success",
        },
      },
    }).then((respuesta) => {
      if (respuesta) {
        window.close();
      }
    });
  };

  // TODO delete
  const onCompletedRejected = () => {
    swal({
      title: "Contenido Rechazado",
      text: "Se envió el motivo de no aprobación",
      buttons: {
        confirm: {
          text: "Ok",
          className: "swal-button swal-button--cancel btn-success",
        },
      },
    }).then((respuesta) => {
      if (respuesta) {
        window.close();
      }
    });
  };

  const onChangeArea = (index, event) => {
    const values = [...areas];
    values[index].area = event.target.value;
    setAreas(values);
  };

  const removeArea = (index) => {
    const values = [...areas];
    values.splice(-1, 1);
    setAreas(values);
  };

  const addArea = () => {
    setAreas([...areas, { area: "" }]);
  };

  const addEptMov = () => {
    setEptMov([...eptMov, { dateDataBase: "", financeInfo: "", rejectRI: "", listReport: "", transferPricing: "", utilityTransferPricing: "", marginRange: "", marginRangeAdjust: "" }])
  }

  const removeEptMov = (index) => {
    const values = [...eptMov];
    values.splice(-1, 1);
    setEptMov(values);
  }

  const onChangeEptMov = (index, event) => {
    const values = [...eptMov];
    values[index][event.target.name] = event.target.value;
    setEptMov(values);
  }

  if (reqForm.loading || callLoading || reqDocument.loading) return <Loading />;
  if (reqForm.error || reqDocument.error) return "Error";
  if (formId == "") return <NotFound />;

  const approval = reqForm.data;
  const { body, status, comments } = approval;
  const formData = body;
  const { document = {} } = reqDocument.data;

  const onSubmit = (values) => {
    values.queryPerm =
      queryPermS.value == "" ? values.queryPerm : queryPermS.value;
    values.formatSignCheck =
      formatSignS.value == "" ? values.formatSignCheck : formatSignS.value;
    values.isTranslation =
      isTranslationS.value == "" ? values.isTranslation : isTranslationS.value;
    values.isComplement =
      isComplementS.value == "" ? values.isComplement : isComplementS.value;
    values.isSubstitution =
      isSubstitutionS.value == ""
        ? values.isSubstitution
        : isSubstitutionS.value;
    values.isReportable =
      codFiscalFederationS.value == ""
        ? values.isReportable
        : codFiscalFederationS.value;
    values.docReportType =
      docReportTypeS.value == "" ? values.docReportType : docReportTypeS.value;
    values.art199 = art199S.value == "" ? values.art199 : art199S.value;
    values.desingScheme =
      desingSchemeS.value == "" ? values.desingScheme : desingSchemeS.value;
    values.clientReport =
      clientReportS.value == "" ? values.clientReport : clientReportS.value;
    values.art197 = art197S.value == "" ? values.art197 : art197S.value;
    values.impetimentLegal =
      impetimentLegalS.value == ""
        ? values.impetimentLegal
        : impetimentLegalS.value;
    values.art197Const =
      art197ConstS.value == "" ? values.art197Const : art197ConstS.value;
    values.relieveAsesor =
      relieveAsesorS.value == "" ? values.relieveAsesor : relieveAsesorS.value;
    values.mountSchema =
      mountSchemaS.value == "" ? values.mountSchema : mountSchemaS.value;
    values.firstComercialization =
      firstComercializationS.value == ""
        ? values.firstComercialization
        : firstComercializationS.value;
    values.art201 = art201S.value == "" ? values.art201 : art201S.value;
    values.declarationInfo =
      declarationInfoS.value == ""
        ? values.declarationInfo
        : declarationInfoS.value;
    values.otherAsesors =
      otherAsesorsS.value == "" ? values.otherAsesors : otherAsesorsS.value;
    values.relieveAsesor =
      relieveAsesorS.value == "" ? values.relieveAsesor : relieveAsesorS.value;
    values.art197pa6 =
      art197pa6S.value == "" ? values.art197pa6 : art197pa6S.value;
    values.asesorArt197pa6 =
      asesorArt197pa6S.value == ""
        ? values.asesorArt197pa6
        : asesorArt197pa6S.value;
    values.relieveSchema =
      relieveSchemaS.value == "" ? values.relieveSchema : relieveSchemaS.value;
    values.constRelieveScheme =
      constRelieveSchemeS.value == ""
        ? values.constRelieveScheme
        : constRelieveSchemeS.value;
    values.emittingArea = areas;
    values.eptOrValuation = eptOrValuationS.value == "" ? values.eptOrValuation : eptOrValuationS.value;
    values.ccPrev = ccPrevS.value == "" ? values.ccPrev : ccPrevS.value;
    values.eptMov = eptMov;

    /*
    if (formData == values) {
      setCallLoading(true);
      callSet({
        body: values,
        status: "APPROVED",
        comments: "",
        userId: userId,
      });
    } else {
      setCallLoading(true);
      callSet({
        status: "APPROVER_CORRECTED",
        body: values,
        comments: "",
        userId: userId,
      });
    }
    */
  };

  const onSubmitRejected = () => {
    /*
    swal({
      text: "Indica el motivo del rechazo",
      content: { element: "textarea" },
      dangerMode: true,
      buttons: {
        confirm: {
          text: "Enviar",
          closeModal: false,
          className: "swal-button swal-button--cancel btn-danger",
        },
        cancel: "Cancelar",
      },
    }).then((motive) => {
      if (!motive) throw null;
      motive = document.querySelector(".swal-content__textarea").value;
      callReject({
        body: formData,
        status: "REJECTED",
        comments: motive,
        userId: userId,
      });
      setCallLoading(true);
    });
    */
  };

  const approval_comments = approval.comments.split(":");
  //const isUserRejeted = user.email == approval_comments[1] ? true : false;

  const handleOnBack = () => history.goBack();

  return (
    <View
      document={document}
      formData={formData}
      onSubmitRejected={onSubmitRejected}
      onSubmit={onSubmit}
      handleOnBack={handleOnBack}
      queryPermS={queryPermS}
      formatSignS={formatSignS}
      setQueryPermS={setQueryPermS}
      setFormatSignS={setFormatSignS}
      FormSchema={FORM_SCHEMA}
      status={status}
      comments={approval_comments[0]}
      codFiscalFederationS={codFiscalFederationS}
      setCodFiscalFederationS={setCodFiscalFederationS}
      isTranslationS={isTranslationS}
      setIsTranslationS={setIsTranslationS}
      isComplementS={isComplementS}
      setIsComplementS={setIsComplementS}
      isSubstitutionS={isSubstitutionS}
      setIsSubstitutionS={setIsSubstitutionS}
      docReportTypeS={docReportTypeS}
      setDocReportTypeS={setDocReportTypeS}
      art199S={art199S}
      setArt199S={setArt199S}
      desingSchemeS={desingSchemeS}
      setDesingSchemeS={setDesingSchemeS}
      clientReportS={clientReportS}
      setClientReportS={setClientReportS}
      art197S={art197S}
      setArt197S={setArt197S}
      impetimentLegalS={impetimentLegalS}
      setImpetimentLegalS={setImpetimentLegalS}
      art197ConstS={art197ConstS}
      setArt197ConstS={setArt197ConstS}
      relieveSchemaS={relieveSchemaS}
      setRelieveSchemaS={setRelieveSchemaS}
      mountSchemaS={mountSchemaS}
      setMountSchemaS={setMountSchemaS}
      firstComercializationS={firstComercializationS}
      setFirstComercializationS={setFirstComercializationS}
      declarationInfoS={declarationInfoS}
      setDeclarationInfoS={setDeclarationInfoS}
      art201S={art201S}
      art5aS={art5aS}
      setArt5aS={setArt5aS}
      reportableGeneralS={reportableGeneralS}
      setReportableGeneralS={setReportableGeneralS}
      setArt201S={setArt201S}
      otherAsesorsS={otherAsesorsS}
      setOtherAsesorsS={setOtherAsesorsS}
      relieveAsesorS={relieveAsesorS}
      setRelieveAsesorS={setRelieveAsesorS}
      art197pa6S={art197pa6S}
      setArt197pa6S={setArt197pa6S}
      asesorArt197pa6S={asesorArt197pa6S}
      setAsesorArt197pa6S={setAsesorArt197pa6S}
      constRelieveSchemeS={constRelieveSchemeS}
      setConstRelieveSchemeS={setConstRelieveSchemeS}
      addArea={addArea}
      removeArea={removeArea}
      onChangeArea={onChangeArea}
      areas={areas}
      eptOrValuationS={eptOrValuationS}
      setEptOrValuationS={setEptOrValuationS}
      eptMov={eptMov}
      setEptMov={setEptMov}
      addEptMov={addEptMov}
      removeEptMov={removeEptMov}
      onChangeEptMov={onChangeEptMov}
      ccPrevS={ccPrevS}
      setCcPrevS={setCcPrevS}
      IS_PROD={IS_PROD}
    />
  );
}

QaApproval.propTypes = {};

export default QaApproval;
