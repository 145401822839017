import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import { ScriptTag, FileField } from "seed/helpers";

const QaRequest = ({
  skipForm = false,
  setSkipForm,

  isEdit = false,
  document = {},
  onChangeAssociatedNotify,
  manualLoad,
  setManualLoad,
  formData,
  status,
  comments,
  queryPermS,
  formatSignS,
  setQueryPermS,
  setFormatSignS,
  onSubmit,
  addPartner,
  removePartner,
  removeAssociated,
  addAssociated,
  inputPartners,
  inputAssociated,
  onChangePartners,
  onChangeAssociated,
  FormSchema,
  dataPartnersAssociated,
  codFiscalFederationS,
  setCodFiscalFederationS,
  isTranslationS,
  setIsTranslationS,
  isComplementS,
  setIsComplementS,
  isSubstitutionS,
  setIsSubstitutionS,
  docReportTypeS,
  setDocReportTypeS,
  art5aS,
  setArt5aS,
  art199S,
  setArt199S,
  desingSchemeS,
  setDesingSchemeS,
  clientReportS,
  setClientReportS,
  art197S,
  setArt197S,
  impetimentLegalS,
  setImpetimentLegalS,
  art197ConstS,
  setArt197ConstS,
  relieveSchemaS,
  setRelieveSchemaS,
  mountSchemaS,
  setMountSchemaS,
  firstComercializationS,
  setFirstComercializationS,
  reportableGeneralS,
  setReportableGeneralS,
  declarationInfoS,
  setDeclarationInfoS,
  art201S,
  setArt201S,
  otherAsesorsS,
  setOtherAsesorsS,
  relieveAsesorS,
  setRelieveAsesorS,
  art197pa6S,
  setArt197pa6S,
  asesorArt197pa6S,
  setAsesorArt197pa6S,
  constRelieveSchemeS,
  setConstRelieveSchemeS,
  areas,
  addArea,
  removeArea,
  onChangeArea,
  IS_PROD,
}) => (
  <>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="card card-lg mb-5">
            <div class="card-body">
              <Formik
                initialValues={formData}
                validationSchema={!skipForm ? FormSchema : null}
                onSubmit={onSubmit}
              >
                {({ values, errors, touched, setFieldValue }) => (
                  <Form>

                    <div class="input-group input-group-merge input-group-flush mr-3 mb-5">
                      <label class="toggle-switch d-flex align-items-center mb-3" for="skip">
                        <input type="checkbox" class="toggle-switch-input" id="skip" name="skip"
                          onChange={() => { setSkipForm(!skipForm) }} checked={skipForm} />
                        <span class="toggle-switch-label">
                          <span class="toggle-switch-indicator"></span>
                        </span>
                        <span class="toggle-switch-content mr-2 my-auto">
                          <h5 class="text-secondary my-auto">Omitir hoja crono</h5>
                        </span>
                      </label>
                    </div>

                    <hr
                      class="my-6"
                      style={{ "border-top": "1px dashed green" }}
                    />

                    {
                      !skipForm ? (
                        <>

                          <h3 class="pb-3">Socio(s) Relacionados</h3>
                          {inputPartners.map((inputPartners, index) => (
                            <div class="form-group" key={index}>
                              <div class="row form-group">
                                <div class="col-sm-8">
                                  <Field
                                    component="select"
                                    name="email"
                                    class="form-control custom-select"
                                    required
                                    onChange={(event) =>
                                      onChangePartners(index, event)
                                    }
                                  >
                                    {inputPartners.email == "" ? (
                                      <option value="">...</option>
                                    ) : (
                                      <option value={inputPartners.email}>
                                        {inputPartners.name}
                                      </option>
                                    )}
                                    {dataPartnersAssociated
                                      .sort((d1, d2) =>
                                        d1.first_name.localeCompare(d2.first_name)
                                      )
                                      .map((data) =>
                                        data.status == "PARTNER" ? (
                                          data.email ==
                                            inputPartners.email ? null : (
                                            <option value={data.email}>
                                              {data.first_name} {data.last_name}
                                            </option>
                                          )
                                        ) : null
                                      )}
                                  </Field>
                                </div>
                              </div>
                            </div>
                          ))}

                          <div class="text-left mb-1">
                            <a
                              class="btn btn-link"
                              onClick={addPartner}
                              style={{ color: "#007473" }}
                            >
                              <i class="fas fa-plus"></i> Agregar Socio
                            </a>
                            {inputPartners.length > 0 ? (
                              <a
                                class="btn btn-link"
                                onClick={removePartner}
                                style={{ color: "#007473" }}
                              >
                                <i class="fas fa-minus"></i> Quitar Socio
                              </a>
                            ) : (
                              <></>
                            )}
                          </div>

                          <h3 class="pb-3">Asociado(s) Relacionados</h3>
                          {inputAssociated.map((inputAssociated, index) => (
                            <div class="form-group" key={index}>
                              <div class="row form-group">
                                <div class="col-sm-8">
                                  <Field
                                    component="select"
                                    name="email"
                                    class="form-control custom-select"
                                    required
                                    onChange={(event) =>
                                      onChangeAssociated(index, event)
                                    }
                                  >
                                    {inputAssociated.email == "" ? (
                                      <option value="">...</option>
                                    ) : (
                                      <option value={inputAssociated.email}>
                                        {inputAssociated.name}
                                      </option>
                                    )}
                                    {dataPartnersAssociated
                                      .sort((d1, d2) =>
                                        d1.first_name.localeCompare(d2.first_name)
                                      )
                                      .map((data) =>
                                        data.status == "ASSOCIATED" ? (
                                          data.email ==
                                            inputAssociated.email ? null : (
                                            <option value={data.email}>
                                              {data.first_name} {data.last_name}
                                            </option>
                                          )
                                        ) : null
                                      )}
                                  </Field>
                                </div>
                                {IS_PROD ? null : (
                                  <div class="col-sm-4 form-check">
                                    {inputAssociated.notify ? (
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        value={inputAssociated.notify}
                                        checked
                                        onChange={(event) =>
                                          onChangeAssociatedNotify(index, event)
                                        }
                                      />
                                    ) : (
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        value={inputAssociated.notify}
                                        onChange={(event) =>
                                          onChangeAssociatedNotify(index, event)
                                        }
                                      />
                                    )}
                                    <label class="form-check-label">
                                      {" "}
                                      Solo notificar al Asociado
                                    </label>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}

                          <div class="text-left mb-1">
                            <a
                              class="btn btn-link"
                              onClick={addAssociated}
                              style={{ color: "#007473" }}
                            >
                              <i class="fas fa-plus"></i> Incluir Asociado
                            </a>
                            {inputAssociated.length > 0 ? (
                              <a
                                class="btn btn-link"
                                onClick={removeAssociated}
                                style={{ color: "#007473" }}
                              >
                                <i class="fas fa-minus"></i> Remover Asociado
                              </a>
                            ) : (
                              <></>
                            )}
                          </div>

                          <hr
                            class="my-6"
                            style={{ "border-top": "1px dashed green" }}
                          />
                          <h2 class="pb-3">
                            Control de Calidad{" "}
                            <a
                              class="btn btn-sm btn-success ml-10"
                              href="/resources/instructivo.pdf"
                              target="_blank"
                            >
                              Ver instrucciones
                            </a>
                          </h2>

                          {status == "REJECTED" ? (
                            comments != "" ? (
                              <div
                                class="alert"
                                style={{
                                  color: "#664d03",
                                  "background-color": "#fff3cd",
                                  "border-color": "#ffecb5",
                                }}
                                role="alert"
                              >
                                Comentarios del ultimo envio: {comments}
                              </div>
                            ) : (
                              <div
                                class="alert"
                                style={{
                                  color: "#664d03",
                                  "background-color": "#fff3cd",
                                  "border-color": "#ffecb5",
                                }}
                                role="alert"
                              >
                                Comentarios del ultimo envio: No se recibieron
                                comentarios.
                              </div>
                            )
                          ) : null}

                          <h3>Aspectos Control Interno</h3>
                          <div class="row form-group">
                            <label
                              for="organizationLabel"
                              class="col-sm-4 col-form-label input-label"
                            >
                              Nombre del Cliente (FILE){" "}
                              <b style={{ color: "red" }}>*</b>
                            </label>
                            <div class="col-sm-8">
                              <Field
                                type="text"
                                class="form-control"
                                name="nameClient"
                                required
                              />
                              {errors.nameClient && touched.nameClient ? (
                                <p class="text-danger mt-2">
                                  {errors.nameClient}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          <div class="row form-group">
                            <label
                              for="organizationLabel"
                              class="col-sm-4 col-form-label input-label"
                            >
                              Compañía a quien se dirige la carta{" "}
                              <b style={{ color: "red" }}>*</b>
                            </label>
                            <div class="col-sm-8">
                              <Field
                                type="text"
                                class="form-control"
                                name="desOrganization"
                                required
                              />
                              {errors.desOrganization &&
                                touched.desOrganization ? (
                                <p class="text-danger mt-2">
                                  {errors.desOrganization}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          <div class="row form-group">
                            <label
                              for="organizationLabel"
                              class="col-sm-2 col-form-label input-label"
                            >
                              Áreas Relacionadas <b style={{ color: "red" }}>*</b>
                            </label>
                            {areas.map((area, index) => (
                              <div class="col-sm-3">
                                <Field
                                  component="select"
                                  name="emittingArea"
                                  class="form-control custom-select mb-2"
                                  value={area.area}
                                  required
                                  onChange={(event) => onChangeArea(index, event)}
                                >
                                  <option value="">...</option>
                                  <option value="Administrativo">
                                    Administrativo
                                  </option>
                                  <option value="Comercio">Comercio</option>
                                  <option value="Contribuciones locales">
                                    Contribuciones locales
                                  </option>
                                  <option value="Fiscal">Fiscal</option>
                                  <option value="Laboral">Laboral</option>
                                  <option value="Legal">Legal</option>
                                  <option value="Legal Admo">Legal Admo</option>
                                  <option value="Patrimonial">Patrimonial</option>
                                  <option value="Precios de transferencia">
                                    Precios de transferencia
                                  </option>
                                  <option value="Propiedad Intelectual">
                                    Propiedad Intelectual
                                  </option>
                                  <option value="Seguridad Social">
                                    Seguridad Social
                                  </option>
                                  <option value="Transaccional">
                                    Transaccional
                                  </option>
                                  <option value="Otra">Otra</option>
                                  <option value="NA">NA</option>
                                </Field>
                              </div>
                            ))}
                            <div class="text-left mb-1">
                              <a
                                class="btn btn-link"
                                onClick={addArea}
                                style={{ color: "#007473" }}
                              >
                                <i class="fas fa-plus"></i> Agregar Área
                              </a>
                              {areas.length > 1 ? (
                                <a
                                  class="btn btn-link"
                                  onClick={removeArea}
                                  style={{ color: "#007473" }}
                                >
                                  <i class="fas fa-minus"></i> Quitar Area
                                </a>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>

                          <div class="row form-group">
                            <label
                              for="organizationLabel"
                              class="col-sm-7 col-form-label input-label"
                            >
                              ¿Se llenó la hoja de control interno EPT o Valuación
                              correspondiente? <b style={{ color: "red" }}>*</b>
                            </label>
                            <div class="col-sm-5">
                              <Field
                                component="select"
                                name="eptOrValuation"
                                class="form-control custom-select"
                                required
                              >
                                <option value="">...</option>
                                <option value="SI">SI</option>
                                <option value="NO">NO</option>
                              </Field>
                              {errors.eptOrValuation && touched.eptOrValuation ? (
                                <p class="text-danger mt-2">
                                  {errors.eptOrValuation}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          <hr
                            class="my-6"
                            style={{ "border-top": "1px dashed green" }}
                          />
                          <h3 class="pb-3">Aspectos Formales</h3>
                          <div class="row form-group">
                            <label
                              for="organizationLabel"
                              class="col-sm-2 col-form-label input-label"
                            >
                              El documento debe permanecer confidencial{" "}
                              <b style={{ color: "red" }}>*</b>
                            </label>
                            <div class="col-sm-4">
                              <Field
                                component="select"
                                name="queryPerm"
                                class="form-control custom-select"
                                required
                                value={
                                  queryPermS.value == ""
                                    ? formData.queryPerm
                                    : queryPermS.value
                                }
                                onChange={(event) =>
                                  setQueryPermS({
                                    value: event.target.value,
                                    hidden:
                                      event.target.value == "SI" ? true : false,
                                  })
                                }
                              >
                                <option value="">...</option>
                                <option value="SI">SI</option>
                                <option value="NO">NO</option>
                              </Field>
                              {errors.queryPerm &&
                                touched.queryPerm &&
                                queryPermS.value == "" ? (
                                <p class="text-danger mt-2">{errors.queryPerm}</p>
                              ) : null}
                            </div>
                            {queryPermS.hidden ||
                              (formData.queryPerm == "SI" &&
                                queryPermS.value == "") ? (
                              <>
                                <label
                                  for="organizationLabel"
                                  class="col-sm-2 col-form-label input-label"
                                >
                                  Explique
                                </label>
                                <div class="col-sm-4">
                                  <Field
                                    type="text"
                                    class="form-control"
                                    name="queryNoperm"
                                    required
                                  />
                                </div>
                              </>
                            ) : null}
                          </div>

                          <div class="row form-group">
                            <label
                              for="organizationLabel"
                              class="col-sm-2 col-form-label input-label"
                            >
                              Iniciales del chequeo{" "}
                              <b style={{ color: "red" }}>*</b>
                            </label>
                            <div class="col-sm-4">
                              <Field
                                type="text"
                                class="form-control"
                                name="initialsCheck"
                                required
                              />
                              {errors.initialsCheck && touched.initialsCheck ? (
                                <p class="text-danger mt-2">
                                  {errors.initialsCheck}
                                </p>
                              ) : null}
                            </div>
                            <label
                              for="organizationLabel"
                              class="col-sm-2 col-form-label input-label"
                            >
                              Iniciales de Revisor{" "}
                              <b style={{ color: "red" }}>*</b>
                            </label>
                            <div class="col-sm-4">
                              <Field
                                type="text"
                                class="form-control"
                                name="initialsReviewer"
                                required
                              />
                              {errors.initialsReviewer &&
                                touched.initialsReviewer ? (
                                <p class="text-danger mt-2">
                                  {errors.initialsReviewer}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          <div class="row form-group">
                            <label
                              for="organizationLabel"
                              class="col-sm-2 col-form-label input-label"
                            >
                              ¿Se respetó el formato de firma?{" "}
                              <b style={{ color: "red" }}>*</b>
                            </label>
                            <div class="col-sm-4">
                              <Field
                                component="select"
                                name="formatSignCheck"
                                class="form-control custom-select"
                                required
                                value={
                                  formatSignS.value == ""
                                    ? formData.formatSignCheck
                                    : formatSignS.value
                                }
                                onChange={(event) =>
                                  setFormatSignS({
                                    value: event.target.value,
                                    hidden:
                                      event.target.value == "NO" ? true : false,
                                  })
                                }
                              >
                                <option value="">...</option>
                                <option value="SI">SI</option>
                                <option value="NO">NO</option>
                              </Field>
                              {errors.formatSignCheck &&
                                touched.formatSignCheck &&
                                formatSignS.value == "" ? (
                                <p class="text-danger mt-2">
                                  {errors.formatSignCheck}
                                </p>
                              ) : null}
                            </div>
                            {formatSignS.hidden ||
                              (formData.formatSignCheck == "NO" &&
                                formatSignS.value == "") ? (
                              <>
                                <label
                                  for="organizationLabel"
                                  class="col-sm-2 col-form-label input-label"
                                >
                                  Explique
                                </label>
                                <div class="col-sm-4">
                                  <Field
                                    type="text"
                                    class="form-control"
                                    name="noFormatSign"
                                    required
                                  />
                                </div>
                              </>
                            ) : null}
                          </div>

                          <div class="row form-group">
                            <label
                              for="organizationLabel"
                              class="col-sm-2 col-form-label input-label"
                            >
                              En caso de tratarse de un documento en inglés, ¿se
                              mandó a traducir? <b style={{ color: "red" }}>*</b>
                            </label>
                            <div class="col-sm-4">
                              <Field
                                component="select"
                                name="isTranslation"
                                class="form-control custom-select"
                                required
                                value={
                                  isTranslationS.value == ""
                                    ? formData.isTranslation
                                    : isTranslationS.value
                                }
                                onChange={(event) =>
                                  setIsTranslationS({
                                    value: event.target.value,
                                    hidden:
                                      event.target.value == "SI" ? true : false,
                                  })
                                }
                              >
                                <option value="">...</option>
                                <option value="SI">SI</option>
                                <option value="NO">NO</option>
                                <option value="N/A">N/A</option>
                              </Field>
                              {errors.isTranslation &&
                                touched.isTranslation &&
                                isTranslationS.value == "" ? (
                                <p class="text-danger mt-2">
                                  {errors.isTranslation}
                                </p>
                              ) : null}
                            </div>
                            {isTranslationS.value == "SI" ||
                              (formData.isTranslation == "SI" &&
                                isTranslationS.value == "") ? (
                              <>
                                <label
                                  for="organizationLabel"
                                  class="col-sm-2 col-form-label input-label"
                                >
                                  Nombre del traductor
                                </label>
                                <div class="col-sm-4">
                                  <Field
                                    type="text"
                                    class="form-control"
                                    name="nameTraslate"
                                    required
                                  />
                                </div>
                              </>
                            ) : null}
                          </div>

                          <div class="row form-group">
                            <label
                              for="organizationLabel"
                              class="col-sm-2 col-form-label input-label"
                            >
                              Se trata de un complemento{" "}
                              <b style={{ color: "red" }}>*</b>
                            </label>
                            <div class="col-sm-4">
                              <Field
                                component="select"
                                name="isComplement"
                                class="form-control custom-select"
                                required
                                value={
                                  isComplementS.value == ""
                                    ? formData.isComplement
                                    : isComplementS.value
                                }
                                onChange={(event) =>
                                  setIsComplementS({
                                    value: event.target.value,
                                    hidden:
                                      event.target.value == "SI" ? true : false,
                                  })
                                }
                              >
                                <option value="">...</option>
                                <option value="SI">SI</option>
                                <option value="NO">NO</option>
                              </Field>
                              {errors.isComplement &&
                                touched.isComplement &&
                                isComplementS.value == "" ? (
                                <p class="text-danger mt-2">
                                  {errors.isComplement}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          {isComplementS.value == "SI" ||
                            (formData.isComplement == "SI" &&
                              isComplementS.value == "") ? (
                            <div class="row form-group ml-6">
                              <div class="col-6 row">
                                <label
                                  for="organizationLabel"
                                  class="col-sm-2 col-form-label input-label"
                                >
                                  Explica
                                </label>
                                <div class="col-sm-10">
                                  <Field
                                    type="text"
                                    class="form-control"
                                    name="isComplementExplain"
                                    required
                                  />
                                </div>
                              </div>
                              <div class="col-6 row">
                                <label
                                  for="organizationLabel"
                                  class="col-sm-4 col-form-label input-label"
                                >
                                  Fecha de Doc. original
                                </label>
                                <div class="col-sm-8">
                                  <Field
                                    type="date"
                                    class="form-control"
                                    name="dateDocComplement"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}

                          <div class="row form-group">
                            <label
                              for="organizationLabel"
                              class="col-sm-2 col-form-label input-label"
                            >
                              Se trata de una sustitución{" "}
                              <b style={{ color: "red" }}>*</b>
                            </label>
                            <div class="col-sm-4">
                              <Field
                                component="select"
                                name="isSubstitution"
                                class="form-control custom-select"
                                required
                                value={
                                  isSubstitutionS.value == ""
                                    ? formData.isSubstitution
                                    : isSubstitutionS.value
                                }
                                onChange={(event) =>
                                  setIsSubstitutionS({
                                    value: event.target.value,
                                    hidden:
                                      event.target.value == "SI" ? true : false,
                                  })
                                }
                              >
                                <option value="">...</option>
                                <option value="SI">SI</option>
                                <option value="NO">NO</option>
                              </Field>
                              {errors.isSubstitution &&
                                touched.isSubstitution &&
                                isSubstitutionS.value == "" ? (
                                <p class="text-danger mt-2">
                                  {errors.isSubstitution}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          {isSubstitutionS.value == "SI" ||
                            (formData.isSubstitution == "SI" &&
                              isSubstitutionS.value == "") ? (
                            <div class="row form-group ml-6">
                              <div class="col-6 row">
                                <label
                                  for="organizationLabel"
                                  class="col-sm-2 col-form-label input-label"
                                >
                                  Explica
                                </label>
                                <div class="col-sm-10">
                                  <Field
                                    type="text"
                                    class="form-control"
                                    name="isSubstitutionExplain"
                                    required
                                  />
                                </div>
                              </div>
                              <div class="col-6 row">
                                <label
                                  for="organizationLabel"
                                  class="col-sm-4 col-form-label input-label"
                                >
                                  Fecha de Doc. original
                                </label>
                                <div class="col-sm-8">
                                  <Field
                                    type="date"
                                    required
                                    class="form-control"
                                    name="dateDocSubstitution"
                                  />
                                </div>
                              </div>
                            </div>
                          ) : null}

                          <hr
                            class="my-6"
                            style={{ "border-top": "1px dashed green" }}
                          />
                          <h3 class="pb-3">Aspectos Técnicos</h3>
                          <div class="row form-group">
                            <label
                              for="organizationLabel"
                              class="col-sm-7 col-form-label input-label"
                            >
                              ¿Se describen claramente los antecedentes?{" "}
                              <b style={{ color: "red" }}>*</b>
                            </label>
                            <div class="col-sm-5">
                              <Field
                                component="select"
                                name="recordDoc"
                                class="form-control custom-select"
                                required
                              >
                                <option value="">...</option>
                                <option value="SI">SI</option>
                                <option value="NO">NO</option>
                              </Field>
                              {errors.recordDoc && touched.recordDoc ? (
                                <p class="text-danger mt-2">{errors.recordDoc}</p>
                              ) : null}
                            </div>
                          </div>

                          <div class="row form-group">
                            <label
                              for="organizationLabel"
                              class="col-sm-7 col-form-label input-label"
                            >
                              ¿La investigación realizada es adecuada y
                              suficiente? <b style={{ color: "red" }}>*</b>
                            </label>
                            <div class="col-sm-5">
                              <Field
                                component="select"
                                name="investigationDoc"
                                class="form-control custom-select"
                                required
                              >
                                <option value="">...</option>
                                <option value="SI">SI</option>
                                <option value="NO">NO</option>
                              </Field>
                              {errors.investigationDoc &&
                                touched.investigationDoc ? (
                                <p class="text-danger mt-2">
                                  {errors.investigationDoc}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          <div class="row form-group">
                            <label
                              for="organizationLabel"
                              class="col-sm-7 col-form-label input-label"
                            >
                              ¿El documento está debidamente fundamentado?{" "}
                              <b style={{ color: "red" }}>*</b>
                            </label>
                            <div class="col-sm-5">
                              <Field
                                component="select"
                                name="supportedDoc"
                                class="form-control custom-select"
                                required
                              >
                                <option value="">...</option>
                                <option value="SI">SI</option>
                                <option value="NO">NO</option>
                              </Field>
                              {errors.supportedDoc && touched.supportedDoc ? (
                                <p class="text-danger mt-2">
                                  {errors.supportedDoc}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          <div class="row form-group">
                            <label
                              for="organizationLabel"
                              class="col-sm-7 col-form-label input-label"
                            >
                              En su caso, ¿se le informa al cliente de los
                              riesgos? <b style={{ color: "red" }}>*</b>
                            </label>
                            <div class="col-sm-5">
                              <Field
                                component="select"
                                name="riskInfoDoc"
                                class="form-control custom-select"
                                required
                              >
                                <option value="">...</option>
                                <option value="SI">SI</option>
                                <option value="NO">NO</option>
                              </Field>
                            </div>
                          </div>

                          <div class="row form-group">
                            <label
                              for="organizationLabel"
                              class="col-sm-7 col-form-label input-label"
                            >
                              ¿Las conclusiones se desprenden de los antecedentes
                              y desarrollo del documento?{" "}
                              <b style={{ color: "red" }}>*</b>
                            </label>
                            <div class="col-sm-5">
                              <Field
                                component="select"
                                name="conclusionSuppDoc"
                                class="form-control custom-select"
                                required
                              >
                                <option value="">...</option>
                                <option value="SI">SI</option>
                                <option value="NO">NO</option>
                              </Field>
                              {errors.conclusionSuppDoc &&
                                touched.conclusionSuppDoc ? (
                                <p class="text-danger mt-2">
                                  {errors.conclusionSuppDoc}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          <div class="row form-group">
                            <label
                              for="organizationLabel"
                              class="col-sm-7 col-form-label input-label"
                            >
                              Por los temas tratados, ¿la debió revisar un socio
                              de otra área distinta a la(s) emisora(s)?{" "}
                              <b style={{ color: "red" }}>*</b>
                            </label>
                            <div class="col-sm-5">
                              <Field
                                component="select"
                                name="additionalReview"
                                class="form-control custom-select"
                                required
                              >
                                <option value="">...</option>
                                <option value="SI">SI</option>
                                <option value="NO">NO</option>
                              </Field>
                              {errors.additionalReview &&
                                touched.additionalReview ? (
                                <p class="text-danger mt-2">
                                  {errors.additionalReview}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div class="row form-group">
                            <label
                              for="organizationLabel"
                              class="col-sm-7 col-form-label input-label"
                            >
                              En su caso, ¿debe remitirse al comité técnico?
                            </label>
                            <div class="col-sm-5">
                              <Field
                                component="select"
                                name="committeReview"
                                class="form-control custom-select"
                                required
                              >
                                <option value="">...</option>
                                <option value="SI">SI</option>
                                <option value="NO">NO</option>
                              </Field>
                            </div>
                          </div>
                          <div class="row form-group">
                            <label
                              for="organizationLabel"
                              class="col-sm-7 col-form-label input-label"
                            >
                              Si se analizan tratados para evitar la doble
                              tributación, ¿se utilizó el apartado de alcance
                              adecuado? <b style={{ color: "red" }}>*</b>
                            </label>
                            <div class="col-sm-5">
                              <Field
                                component="select"
                                name="taxationFormat"
                                class="form-control custom-select"
                                required
                              >
                                <option value="">...</option>
                                <option value="SI">SI</option>
                                <option value="NO">NO</option>
                                <option value="NA">NA</option>
                              </Field>
                              {errors.taxationFormat && touched.taxationFormat ? (
                                <p class="text-danger mt-2">
                                  {errors.taxationFormat}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <hr
                            class="my-6"
                            style={{ "border-top": "1px dashed green" }}
                          />
                          <h3 class="pb-3">Esquemas Reportables</h3>
                          <div class="row form-group">
                            <label
                              for="organizationLabel"
                              class="col-sm-7 col-form-label input-label"
                            >
                              El documento es objeto de análisis en materia de
                              esquema reportables{" "}
                              <b style={{ color: "red" }}>*</b>
                            </label>
                            <div class="col-sm-5">
                              <Field
                                component="select"
                                name="isReportable"
                                class="form-control custom-select"
                                required
                                value={
                                  codFiscalFederationS.value == ""
                                    ? formData.isReportable
                                    : codFiscalFederationS.value
                                }
                                onChange={(event) =>
                                  setCodFiscalFederationS({
                                    value: event.target.value,
                                  })
                                }
                              >
                                <option value="">...</option>
                                <option value="SI">SI</option>
                                <option value="NO">NO</option>
                              </Field>
                              {errors.codFiscalFederation &&
                                touched.codFiscalFederation &&
                                codFiscalFederationS == "" ? (
                                <p class="text-danger mt-2">
                                  {errors.codFiscalFederation}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          {
                            (codFiscalFederationS.value == "SI" ||
                              (formData.isReportable == "SI" &&
                                codFiscalFederationS.value == "")) ? (
                              <div>
                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-7 col-form-label input-label"
                                  >
                                    1. ¿El documento contiene algún plan,
                                    proyecto, propuesta, asesoría, instrucción o
                                    recomendación externada de forma expresa o
                                    tácita con el objeto de materializar una serie
                                    de actos jurídicos?
                                  </label>
                                  <div class="col-sm-5">
                                    <Field
                                      component="select"
                                      name="docReportType"
                                      class="form-control custom-select"
                                      required
                                      value={
                                        docReportTypeS.value == ""
                                          ? formData.docReportType
                                          : docReportTypeS.value
                                      }
                                      onChange={(event) =>
                                        setDocReportTypeS({
                                          value: event.target.value,
                                        })
                                      }
                                    >
                                      <option value="">...</option>
                                      <option value="SI">SI</option>
                                      <option value="NO">NO</option>
                                    </Field>
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-7 col-form-label input-label"
                                  >
                                    2. En caso de que la respuesta anterior sea
                                    afirmativa, ¿El plan, proyecto, propuesta,
                                    asesoría, instrucción o recomendación genera o
                                    puede generar, directa o indirectamente, la
                                    obtención de un beneficio fiscal en méxico en
                                    términos de lo dispuesto por el quinto párrafo
                                    del artículo 5-a del código fiscal de la
                                    federación?
                                  </label>
                                  <div class="col-sm-5">
                                    <Field
                                      component="select"
                                      name="art5a"
                                      class="form-control custom-select"
                                      value={
                                        art5aS.value == ""
                                          ? formData.art5a
                                          : art5aS.value
                                      }
                                      onChange={(event) =>
                                        setArt5aS({
                                          value: event.target.value,
                                        })
                                      }
                                      required
                                    >
                                      <option value="">...</option>
                                      <option value="SI">SI</option>
                                      <option value="NO">NO</option>
                                      <option value="NA">NA</option>
                                    </Field>
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-7 col-form-label input-label"
                                  >
                                    3. En caso de que la pregunta 1 sea afirmativa
                                    y el plan, proyecto, propuesta, asesoría,
                                    instrucción o recomendación sí genere o pueda
                                    generar, directa o indirectamente, la
                                    obtención de un beneficio fiscal en méxico en
                                    términos de lo dispuesto por el quinto párrafo
                                    del artículo 5-a del código fiscal de la
                                    federación, ¿este tiene alguna de las
                                    características previstas en las fracciones i
                                    a xiv del artículo 199 del código fiscal de la
                                    federación?
                                  </label>
                                  <div class="col-sm-5">
                                    <Field
                                      component="select"
                                      name="art199"
                                      class="form-control custom-select"
                                      required
                                      value={
                                        art199S.value == ""
                                          ? formData.art199
                                          : art199S.value
                                      }
                                      onChange={(event) =>
                                        setArt199S({
                                          value: event.target.value,
                                        })
                                      }
                                    >
                                      <option value="">...</option>
                                      <option value="SI">SI</option>
                                      <option value="NO">NO</option>
                                      <option value="NA">NA</option>
                                    </Field>
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm col-form-label input-label"
                                  >
                                    4. En caso de que la respuesta a la pregunta
                                    anterior sea afirmativa, ¿Qué fracción es y
                                    porque?
                                  </label>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-2 col-form-label input-label"
                                  >
                                    Fracción
                                  </label>
                                  <div class="col-sm-10">
                                    <Field
                                      type="text"
                                      class="form-control"
                                      name="isFraction"
                                    />
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-2 col-form-label input-label"
                                  >
                                    Razón
                                  </label>
                                  <div class="col-sm-10">
                                    <Field
                                      type="text"
                                      class="form-control"
                                      name="isReason"
                                    />
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-7 col-form-label input-label"
                                  >
                                    5. Si el documento contiene un esquema
                                    reportable de conformidad con el título sexto
                                    del código fiscal de la federación, ¿el citado
                                    esquema fue diseñado, organizado, implementado
                                    y administrado por el cliente?
                                  </label>
                                  <div class="col-sm-5">
                                    <Field
                                      component="select"
                                      name="desingScheme"
                                      class="form-control custom-select"
                                      required
                                      value={
                                        desingSchemeS.value == ""
                                          ? formData.desingScheme
                                          : desingSchemeS.value
                                      }
                                      onChange={(event) =>
                                        setDesingSchemeS({
                                          value: event.target.value,
                                        })
                                      }
                                    >
                                      <option value="">...</option>
                                      <option value="SI">SI</option>
                                      <option value="NO">NO</option>
                                      <option value="NA">NA</option>
                                    </Field>
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-7 col-form-label input-label"
                                  >
                                    6. En caso de que la respuesta a la pregunta
                                    anterior sea firmativa, ¿Se tiene alguna
                                    evidencia o constancia de esta situación y que
                                    el cliente será quien informe el esquema
                                    reportable?
                                  </label>
                                  <div class="col-sm-5">
                                    <Field
                                      component="select"
                                      name="clientReport"
                                      class="form-control custom-select"
                                      required
                                      value={
                                        clientReportS.value == ""
                                          ? formData.clientReport
                                          : clientReportS.value
                                      }
                                      onChange={(event) =>
                                        setClientReportS({
                                          value: event.target.value,
                                        })
                                      }
                                    >
                                      <option value="">...</option>
                                      <option value="SI">SI</option>
                                      <option value="NO">NO</option>
                                      <option value="NA">NA</option>
                                    </Field>
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-5 col-form-label input-label"
                                  >
                                    En caso afirmativo especifique que evidencia
                                    se tiene
                                  </label>
                                  <div class="col-sm-7">
                                    <Field
                                      type="text"
                                      class="form-control"
                                      name="clientReportExplainB"
                                    />
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-2 col-form-label input-label"
                                  >
                                    En caso negativo explique
                                  </label>
                                  <div class="col-sm-10">
                                    <Field
                                      type="text"
                                      class="form-control"
                                      name="clientReportExplainA"
                                    />
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-7 col-form-label input-label"
                                  >
                                    7. En caso de que el documento sí contenga un
                                    “esquema” que genere o pueda generar, directa
                                    o indirectamente, la obtención de un beneficio
                                    fiscal en méxico, pero no sea “reportable” de
                                    conformidad con el artículo 199 del código
                                    fiscal de la federación, ¿ya se expidió o se
                                    expedirá la constancia que se prevé en el
                                    artículo 197, séptimo párrafo del código
                                    fiscal de la federación y se entregó o se
                                    entregará dentro del plazo de 5 días previsto
                                    en dicho artículo?
                                  </label>
                                  <div class="col-sm-5">
                                    <Field
                                      component="select"
                                      name="art197"
                                      class="form-control custom-select"
                                      required
                                      value={
                                        art197S.value == ""
                                          ? formData.art197
                                          : art197S.value
                                      }
                                      onChange={(event) =>
                                        setArt197S({
                                          value: event.target.value,
                                        })
                                      }
                                    >
                                      <option value="">...</option>
                                      <option value="SI">SI</option>
                                      <option value="NO">NO</option>
                                      <option value="NA">NA</option>
                                    </Field>
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-2 col-form-label input-label"
                                  >
                                    En caso negativo Explique
                                  </label>
                                  <div class="col-sm-10">
                                    <Field
                                      type="text"
                                      class="form-control"
                                      name="art197Explain"
                                    />
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-7 col-form-label input-label"
                                  >
                                    8. En caso de que la pregunta 1 sea afirmativa
                                    y el plan, proyecto, propuesta, asesoría,
                                    instrucción o recomendación sí genere o pueda
                                    generar, directa o indirectamente, la
                                    obtención de un beneficio fiscal en méxico en
                                    términos de lo dispuesto por el quinto párrafo
                                    del artículo 5-a del código fiscal de la
                                    federación, ¿existe algún impedimento legal
                                    para que se revele el esquema reportable?
                                  </label>
                                  <div class="col-sm-5">
                                    <Field
                                      component="select"
                                      name="impetimentLegal"
                                      class="form-control custom-select"
                                      required
                                      value={
                                        impetimentLegalS.value == ""
                                          ? formData.impetimentLegal
                                          : impetimentLegalS.value
                                      }
                                      onChange={(event) =>
                                        setImpetimentLegalS({
                                          value: event.target.value,
                                        })
                                      }
                                    >
                                      <option value="">...</option>
                                      <option value="SI">SI</option>
                                      <option value="NO">NO</option>
                                      <option value="NA">NA</option>
                                    </Field>
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-7 col-form-label input-label"
                                  >
                                    9. En caso de que la respuesta a la pregunta
                                    anterior sea afirmativa, ¿Ya se expidió o se
                                    expedirá la constancia que se prevé en el
                                    artículo 197, séptimo párrafo del código
                                    fiscal de la federación y se entregó o se
                                    entregará dentro del plazo de 5 días previsto
                                    en dicho artículo?
                                  </label>
                                  <div class="col-sm-5">
                                    <Field
                                      component="select"
                                      name="art197Const"
                                      class="form-control custom-select"
                                      required
                                      value={
                                        art197ConstS.value == ""
                                          ? formData.art197Const
                                          : art197ConstS.value
                                      }
                                      onChange={(event) =>
                                        setArt197ConstS({
                                          value: event.target.value,
                                        })
                                      }
                                    >
                                      <option value="">...</option>
                                      <option value="SI">SI</option>
                                      <option value="NO">NO</option>
                                      <option value="NA">NA</option>
                                    </Field>
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-2 col-form-label input-label"
                                  >
                                    En caso negativo Explique
                                  </label>
                                  <div class="col-sm-10">
                                    <Field
                                      type="text"
                                      class="form-control"
                                      name="art197ConstExplain"
                                    />
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-7 col-form-label input-label"
                                  >
                                    10. En caso de que la respuesta a la pregunta
                                    1 sea afirmativa, ¿El plan, proyecto,
                                    propuesta, asesoría, instrucción o
                                    recomendación tiene por objeto evitar revelar
                                    algún esquema reportable?
                                  </label>
                                  <div class="col-sm-5">
                                    <Field
                                      component="select"
                                      name="relieveSchema"
                                      class="form-control custom-select"
                                      required
                                      value={
                                        relieveSchemaS.value == ""
                                          ? formData.relieveSchema
                                          : relieveSchemaS.value
                                      }
                                      onChange={(event) =>
                                        setRelieveSchemaS({
                                          value: event.target.value,
                                        })
                                      }
                                    >
                                      <option value="">...</option>
                                      <option value="SI">SI</option>
                                      <option value="NO">NO</option>
                                      <option value="NA">NA</option>
                                    </Field>
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-7 col-form-label input-label"
                                  >
                                    11. En caso de que la respuesta a la pregunta
                                    3 sea afirmativa, ¿Se revisó si el monto del
                                    esquema supera el umbral establecido en el
                                    acuerdo secretarial emitido por la secretaría
                                    de hacienda y crédito público?
                                  </label>
                                  <div class="col-sm-5">
                                    <Field
                                      component="select"
                                      name="mountSchema"
                                      class="form-control custom-select"
                                      required
                                      value={
                                        mountSchemaS.value == ""
                                          ? formData.mountSchema
                                          : mountSchemaS.value
                                      }
                                      onChange={(event) =>
                                        setMountSchemaS({
                                          value: event.target.value,
                                        })
                                      }
                                    >
                                      <option value="">...</option>
                                      <option value="SI">SI</option>
                                      <option value="NO">NO</option>
                                      <option value="NA">NA</option>
                                    </Field>
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-2 col-form-label input-label"
                                  >
                                    En caso negativo Explique
                                  </label>
                                  <div class="col-sm-10">
                                    <Field
                                      type="text"
                                      class="form-control"
                                      name="mountSchemaExplain"
                                    />
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-7 col-form-label input-label"
                                  >
                                    12. Si el documento contiene un esquema
                                    reportable de conformidad con el título sexto
                                    del código fiscal de la federación, ¿se
                                    participó en la totalidad del citado esquema
                                    reportable?
                                  </label>
                                  <div class="col-sm-5">
                                    <Field
                                      component="select"
                                      name="citReportable"
                                      class="form-control custom-select"
                                      required
                                    >
                                      <option value="">...</option>
                                      <option value="SI">SI</option>
                                      <option value="NO">NO</option>
                                      <option value="NA">NA</option>
                                    </Field>
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-7 col-form-label input-label"
                                  >
                                    13. Si el documento contiene un esquema
                                    reportable de conformidad con el título sexto
                                    del código fiscal de la federación, ¿el plan,
                                    proyecto, asesoría o recomendación contenido
                                    en el documento califica como esquema
                                    reportable generalizado o personalizado?
                                  </label>
                                  <div class="col-sm-5">
                                    <Field
                                      component="select"
                                      name="reportableGeneral"
                                      class="form-control custom-select"
                                      required
                                      value={
                                        reportableGeneralS.value == ""
                                          ? formData.reportableGeneral
                                          : reportableGeneralS.value
                                      }
                                      onChange={(event) =>
                                        setReportableGeneralS({
                                          value: event.target.value,
                                        })
                                      }
                                    >
                                      <option value="">...</option>
                                      <option value="Generalizado">
                                        Generalizado
                                      </option>
                                      <option value="Personalizado">
                                        Personalizado
                                      </option>
                                      <option value="NA">NA</option>
                                    </Field>
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-7 col-form-label input-label"
                                  >
                                    14. En caso de que el plan, proyecto, asesoría
                                    o recomendación contenido en el documento
                                    califique como esquema reportable
                                    generalizado, ¿es la primera vez que se
                                    pretende efectuar su comercialización?
                                  </label>
                                  <div class="col-sm-5">
                                    <Field
                                      component="select"
                                      name="firstComercialization"
                                      class="form-control custom-select"
                                      required
                                      value={
                                        firstComercializationS.value == ""
                                          ? formData.firstComercialization
                                          : firstComercializationS.value
                                      }
                                      onChange={(event) =>
                                        setFirstComercializationS({
                                          value: event.target.value,
                                        })
                                      }
                                    >
                                      <option value="">...</option>
                                      <option value="SI">SI</option>
                                      <option value="NO">NO</option>
                                      <option value="NA">NA</option>
                                    </Field>
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm col-form-label input-label"
                                  >
                                    15. En caso de que la respuesta anterior sea
                                    negativa, Señala los datos de la declaración a
                                    traves de la cual se revelo dicho esquema
                                  </label>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-2 col-form-label input-label"
                                  >
                                    Datos
                                  </label>
                                  <div class="col-sm-10">
                                    <Field
                                      type="text"
                                      class="form-control"
                                      name="dataDeclaration"
                                    />
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-7 col-form-label input-label"
                                  >
                                    16. En caso de que la respuesta a la pregunta
                                    14 sea afirmativa, ¿Ya se procedió o se
                                    procederá a la presentación de la declaración
                                    informativa correspondiente dentro del plazo
                                    de 30 días previsto en el artículo 201 del
                                    código fiscal de la federación?
                                  </label>
                                  <div class="col-sm-5">
                                    <Field
                                      component="select"
                                      name="declarationInfo"
                                      class="form-control custom-select"
                                      required
                                      value={
                                        declarationInfoS.value == ""
                                          ? formData.declarationInfo
                                          : declarationInfoS.value
                                      }
                                      onChange={(event) =>
                                        setDeclarationInfoS({
                                          value: event.target.value,
                                        })
                                      }
                                    >
                                      <option value="">...</option>
                                      <option value="SI">SI</option>
                                      <option value="NO">NO</option>
                                      <option value="NA">NA</option>
                                    </Field>
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-2 col-form-label input-label"
                                  >
                                    En caso negativo explique
                                  </label>
                                  <div class="col-sm-10">
                                    <Field
                                      type="text"
                                      class="form-control"
                                      name="declarationInfoExplain"
                                    />
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-7 col-form-label input-label"
                                  >
                                    17. En caso de que el plan, proyecto, asesoría
                                    o recomendación califique como esquema
                                    reportable personalizado, ¿ya se procedió o se
                                    procederá a la presentación de la declaración
                                    informativa correspondiente dentro del plazo
                                    de 30 días previsto en el artículo 201 del
                                    código fiscal de la federación?
                                  </label>
                                  <div class="col-sm-5">
                                    <Field
                                      component="select"
                                      name="art201"
                                      class="form-control custom-select"
                                      required
                                      value={
                                        art201S.value == ""
                                          ? formData.art201
                                          : art201S.value
                                      }
                                      onChange={(event) =>
                                        setArt201S({
                                          value: event.target.value,
                                        })
                                      }
                                    >
                                      <option value="">...</option>
                                      <option value="SI">SI</option>
                                      <option value="NO">NO</option>
                                      <option value="NA">NA</option>
                                    </Field>
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-2 col-form-label input-label"
                                  >
                                    En caso negativo explique
                                  </label>
                                  <div class="col-sm-10">
                                    <Field
                                      type="text"
                                      class="form-control"
                                      name="art201Explain"
                                    />
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-7 col-form-label input-label"
                                  >
                                    18. Si el documento contiene un esquema
                                    reportable de conformidad con el título sexto
                                    del código fiscal de la federación, ¿en el
                                    diseño o implementación del citado esquema
                                    reportable participaron otros asesores?
                                  </label>
                                  <div class="col-sm-5">
                                    <Field
                                      component="select"
                                      name="otherAsesors"
                                      class="form-control custom-select"
                                      required
                                      value={
                                        otherAsesorsS.value == ""
                                          ? formData.otherAsesors
                                          : otherAsesorsS.value
                                      }
                                      onChange={(event) =>
                                        setOtherAsesorsS({
                                          value: event.target.value,
                                        })
                                      }
                                    >
                                      <option value="">...</option>
                                      <option value="SI">SI</option>
                                      <option value="NO">NO</option>
                                      <option value="NA">NA</option>
                                    </Field>
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm col-form-label input-label"
                                  >
                                    19. En caso de que la respuesta a la pregunta
                                    anterior sea afirmativa, ¿quienes
                                    participaron?
                                  </label>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-2 col-form-label input-label"
                                  >
                                    Nombre o denominación de otros asesores
                                  </label>
                                  <div class="col-sm-10">
                                    <Field
                                      type="text"
                                      class="form-control"
                                      name="nameOtherAsesors"
                                    />
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-7 col-form-label input-label"
                                  >
                                    20. En caso de que la respuesta a la pregunta
                                    18 sea afirmativa, ¿Se tiene un acuerdo con
                                    los otros asesores de quién será el encargado
                                    de revelar el esquema?
                                  </label>
                                  <div class="col-sm-5">
                                    <Field
                                      component="select"
                                      name="relieveAsesor"
                                      class="form-control custom-select"
                                      required
                                      value={
                                        relieveAsesorS.value == ""
                                          ? formData.relieveAsesor
                                          : relieveAsesorS.value
                                      }
                                      onChange={(event) =>
                                        setRelieveAsesorS({
                                          value: event.target.value,
                                        })
                                      }
                                    >
                                      <option value="">...</option>
                                      <option value="SI">SI</option>
                                      <option value="NO">NO</option>
                                      <option value="NA">NA</option>
                                    </Field>
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-5 col-form-label input-label"
                                  >
                                    En caso afirmativo mencionar el nombre o
                                    denominacion del asesor que reportara
                                  </label>
                                  <div class="col-sm-7">
                                    <Field
                                      type="text"
                                      class="form-control"
                                      name="relieveAsesorName"
                                    />
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-2 col-form-label input-label"
                                  >
                                    En caso negativo explique
                                  </label>
                                  <div class="col-sm-10">
                                    <Field
                                      type="text"
                                      class="form-control"
                                      name="relieveAsesorExplain"
                                    />
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-7 col-form-label input-label"
                                  >
                                    21. Si se tiene un acuerdo con los otros
                                    asesores de que CRZ será el encargado de
                                    revelar el esquema, ¿ya se expidió o se
                                    expedirá a los otros asesores la constancia
                                    que se prevé en el artículo 197, sexto párrafo
                                    del código fiscal de la federación, anexándole
                                    la información que se detalla en dicho
                                    párrafo?
                                  </label>
                                  <div class="col-sm-5">
                                    <Field
                                      component="select"
                                      name="art197pa6"
                                      class="form-control custom-select"
                                      required
                                      value={
                                        art197pa6S.value == ""
                                          ? formData.art197pa6
                                          : art197pa6S.value
                                      }
                                      onChange={(event) =>
                                        setArt197pa6S({
                                          value: event.target.value,
                                        })
                                      }
                                    >
                                      <option value="">...</option>
                                      <option value="SI">SI</option>
                                      <option value="NO">NO</option>
                                      <option value="NA">NA</option>
                                    </Field>
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-2 col-form-label input-label"
                                  >
                                    En caso negativo explique
                                  </label>
                                  <div class="col-sm-10">
                                    <Field
                                      type="text"
                                      class="form-control"
                                      name="art197pa6Explain"
                                    />
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-7 col-form-label input-label"
                                  >
                                    22. Si se tiene un acuerdo con los otros
                                    asesores de que otro asesor será el encargado
                                    de revelar el esquema, ¿ya se recibió o se
                                    recibirá del otro asesor la constancia que se
                                    prevé en el artículo 197, sexto párrafo del
                                    código fiscal de la federación, conjuntamente
                                    con la información que se detalla en dicho
                                    párrafo?
                                  </label>
                                  <div class="col-sm-5">
                                    <Field
                                      component="select"
                                      name="asesorArt197pa6"
                                      class="form-control custom-select"
                                      required
                                      value={
                                        asesorArt197pa6S.value == ""
                                          ? formData.asesorArt197pa6
                                          : asesorArt197pa6S.value
                                      }
                                      onChange={(event) =>
                                        setAsesorArt197pa6S({
                                          value: event.target.value,
                                        })
                                      }
                                    >
                                      <option value="">...</option>
                                      <option value="SI">SI</option>
                                      <option value="NO">NO</option>
                                      <option value="NA">NA</option>
                                    </Field>
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-2 col-form-label input-label"
                                  >
                                    En caso negativo explique
                                  </label>
                                  <div class="col-sm-10">
                                    <Field
                                      type="text"
                                      class="form-control"
                                      name="asesorArt197pa6Explain"
                                    />
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-7 col-form-label input-label"
                                  >
                                    23. Si el documento contiene un esquema
                                    reportable de conformidad con el título sexto
                                    del código fiscal de la federación, ¿se tiene
                                    un acuerdo que será el cliente el encargado de
                                    revelar el esquema?
                                  </label>
                                  <div class="col-sm-5">
                                    <Field
                                      component="select"
                                      name="constRelieveScheme"
                                      class="form-control custom-select"
                                      required
                                      value={
                                        constRelieveSchemeS.value == ""
                                          ? formData.constRelieveScheme
                                          : constRelieveSchemeS.value
                                      }
                                      onChange={(event) =>
                                        setConstRelieveSchemeS({
                                          value: event.target.value,
                                        })
                                      }
                                    >
                                      <option value="">...</option>
                                      <option value="SI">SI</option>
                                      <option value="NO">NO</option>
                                      <option value="NA">NA</option>
                                    </Field>
                                  </div>
                                </div>

                                <div class="row form-group">
                                  <label
                                    for="organizationLabel"
                                    class="col-sm-2 col-form-label input-label"
                                  >
                                    En caso negativo explique
                                  </label>
                                  <div class="col-sm-10">
                                    <Field
                                      type="text"
                                      class="form-control"
                                      name="constRelieveSchemeExplain"
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : null
                          }
                          <hr
                            class="my-6"
                            style={{ "border-top": "1px dashed green" }}
                          />

                          <h3 class="pb-3">Criterios no vinculativos</h3>
                          <div class="row form-group">
                            <label
                              for="organizationLabel"
                              class="col-sm-7 col-form-label input-label"
                            >
                              1. ¿Nos cercioramos si la opinión que vamos a emitir
                              se encuentra comprendida dentro de los ‘criterios no
                              vinculativos de las disposiciones fiscales y
                              aduaneras’? <b style={{ color: "red" }}>*</b>
                            </label>
                            <div class="col-sm-5">
                              <Field
                                component="select"
                                name="nonBindingCrit"
                                class="form-control custom-select"
                                required
                              >
                                <option value="">...</option>
                                <option value="SI">SI</option>
                                <option value="NO">NO</option>
                              </Field>
                              {errors.nonBindingCrit && touched.nonBindingCrit ? (
                                <p class="text-danger mt-2">
                                  {errors.nonBindingCrit}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          <div class="row form-group">
                            <label
                              for="organizationLabel"
                              class="col-sm-7 col-form-label input-label"
                            >
                              2. Si el contenido de la opinión difiere de los
                              ‘criterios no vinculativos de las disposiciones
                              fiscales y aduaneras’, ¿Se le advirtió al cliente
                              sobre la existencia del criterio contrario publicado
                              por las autoridades fiscales?{" "}
                              <b style={{ color: "red" }}>*</b>
                            </label>
                            <div class="col-sm-5">
                              <Field
                                component="select"
                                name="difNonBindingCrit"
                                class="form-control custom-select"
                                required
                              >
                                <option value="">...</option>
                                <option value="SI">SI</option>
                                <option value="NO">NO</option>
                                <option value="NA">NA</option>
                              </Field>
                              {errors.difNonBindingCrit &&
                                touched.difNonBindingCrit ? (
                                <p class="text-danger mt-2">
                                  {errors.difNonBindingCrit}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <hr
                            class="my-6"
                            style={{ "border-top": "1px dashed green" }}
                          />
                        </>
                      ) : null
                    }

                    <h3 class="pb-3">Datos del archivo</h3>

                    <div class="form-group">
                      <label class="input-label">Nombre de archivo</label>
                      <Field type="text" name="title" class="form-control" required />
                    </div>

                    <div class="d-flex align-items-center mr-2">
                      <div class="input-group input-group-merge input-group-flush mr-3">
                        <label class="toggle-switch d-flex align-items-center mb-3" for="manualLoad">
                          <input type="checkbox" class="toggle-switch-input" id="manualLoad" name="manualLoad"
                            onChange={() => { setManualLoad(!manualLoad) }} checked={manualLoad}
                          />
                          <span class="toggle-switch-label">
                            <span class="toggle-switch-indicator"></span>
                          </span>
                          <span class="toggle-switch-content mr-2">
                            <span class="d-block">Enlace NetDocuments</span>
                          </span>

                        </label>
                      </div>
                    </div>

                    {!manualLoad ? (
                      <div class="mb-3">
                        <FileField name="file" setFieldValue={setFieldValue} required />
                        {
                          isEdit && document.file ? (
                            <a href={document.file ? document.file.url :
                              `https://vault.netvoyage.com/neWeb2/goid.aspx?id=${document.request.ndDocumentId}`}
                              target="_blank" rel="noreferrer" class="btn btn-primary mt-3">
                              Ir al archivo
                            </a>
                          ) : null
                        }
                      </div>
                    ) : (
                      <div class="form-group">
                        <label class="input-label">Link de netdocuments</label>
                        <Field type="text" name="linkNetdocuments" class="form-control" required />
                      </div>
                    )}

                    <div class="form-group">
                      <label class="input-label">Nombre del cliente</label>
                      <Field type="text" name="client" class="form-control" required />
                    </div>

                    <div class="form-group">
                      <label class="input-label">Fecha de envío</label>
                      <Field type="date" name="syncDate" class="form-control" required />
                    </div>

                    <div class="form-group">
                      <label class="input-label">Fecha de documento</label>
                      <Field type="date" name="documentDate" class="form-control" required />
                    </div>

                    <button
                      type="submit"
                      class="btn btn-lg btn-block btn-success mt-6"
                    >
                      Finalizar Proceso
                    </button>

                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

    </div>
    <ScriptTag
      content={`
        $(document).ready(function() {
          $("#start, #end").on("click", function(e) {
            e.preventDefault()
            $(this).prop("disabled", !$(this).prop("disabled"))
            $(this).siblings().prop("disabled", !$(this).siblings().prop("disabled"))
          })
        })
      `}
    />
  </>
);

QaRequest.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  addPartner: PropTypes.func,
  removePartner: PropTypes.func,
  inputFields: PropTypes.array,
  onChangePartners: PropTypes.func,
  onChangeAssociated: PropTypes.func,
  changeInclude: PropTypes.func,
  addAssociated: PropTypes.func,
  removeAssociated: PropTypes.func,
  inputPartners: PropTypes.array,
  inputAssociated: PropTypes.array,
  dataPartnersAssociated: PropTypes.array,
  FormSchema: PropTypes.object,
};

export default QaRequest;
