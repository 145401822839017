import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter } from "react-router-dom";
import { ModalRoute } from "seed/helpers";
import Review from "components/partner/Review";
import Empty from "components/partner/Empty"
import QaApprovalCC from "components/partner/QaApprovalCC";

const MainView = ({ review, reviewed = false, id, token }) => (

  <main id="content" role="main" class="mt-5">

    <div
      class="position-fixed top-0 right-0 left-0 bg-img-hero"
      style={{
        height: "32rem",
        backgroundImage: "url(/theme/svg/components/abstract-bg-4.svg)",
      }}
    >
      <figure class="position-absolute right-0 bottom-0 left-0">
        <svg
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1921 273"
        >
          <polygon fill="#00454d" points="0,273 1921,273 1921,0 " />
        </svg>
      </figure>
    </div>


    <div class="container content align-items-center justify-content-center">
      <a class="d-flex justify-content-center mb-5">
        <img
          class="z-index-2"
          src="/theme/svg/logos/logoCrz.png"
          alt="Logo"
          style={{ width: "8rem" }}
        />
      </a>

      {!reviewed ? <Review review={review} id={id} token={token}/> : <Empty />}
    </div>

  </main>


);

MainView.propTypes = {
  review: PropTypes.object.isRequired,
  reviewed: PropTypes.bool
};

export default MainView;