import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import QaApprovalCC from "components/partner/QaApprovalCC";
import { ModalRoute } from "seed/helpers";


function getTitle(data) {
  return (data).title;
}


const ReviewView = ({ review, reviewed, onSubmit, error, approvalId, handleOnClick, previousValues }) => (

  <div class="d-flex justify-content-center">
    <div class="card w-50 p-5">

      {/* Header */}
      {
        !reviewed ? (
          <div class="card-header">
            <h4 class="card-header-title">REVISIÓN DE DOCUMENTO
              <p className="mt-3">Fecha límite {moment(review.deadline).format("DD-MMM-YYYY").replace(".", "")}
                {review.isDelayed && (
                  <small class="ml-2 text-danger">(Retrasado)</small>
                )}
              </p>
            </h4>
          </div>
        ) : null
      }

      {/* Body */}
      <div class="card-body">
        <div class="row">
          <div class="col">

            {
              reviewed ? (
                <div style={{ height: "200px" }}>
                  <h4>Revisión enviada con éxito</h4>
                  <h6 class="mt-4">
                    La revisión ha sido creada con éxito, esta será revisada por el equipo de control de calidad
                  </h6>
                </div>) :
                (
                  <Formik initialValues={previousValues} onSubmit={onSubmit}>
                    {({ values, setFieldValue }) => (
                      <Form>

                        <div class="row mb-3">
                          <div class="col-md-8">
                            {getTitle(review.document.data)}
                          </div>
                          <div class="col-md-4">
                            <a class="btn btn-outline-primary" onClick={() => handleOnClick(values)}>
                              Abrir opinión
                            </a>
                            {/* 
                        <a href={review.document.file ? review.document.file.url : 
                          `https://vault.netvoyage.com/neWeb2/goid.aspx?id=${review.document.request.ndDocumentId}`} 
                          target="_blank" rel="noreferrer" class="btn btn-outline-primary">
                          Abrir documento
                        </a>
                        */}
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col">
                            Enviar comentarios
                          </div>
                          <div class="col">

                            <div class="input-group input-group-md-down-break">
                              <div class="form-control">
                                <div class="custom-control custom-radio">
                                  <Field type="radio" class="custom-control-input"
                                    name="hasComments" id="hasCommentsYes" value="Yes" />
                                  <label class="custom-control-label" for="hasCommentsYes">Si</label>
                                </div>
                              </div>

                              <div class="form-control">
                                <div class="custom-control custom-radio">
                                  <Field type="radio" class="custom-control-input"
                                    name="hasComments" id="hasCommentsNo" value="No" />
                                  <label class="custom-control-label" for="hasCommentsNo">No</label>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>

                        {
                          values["hasComments"] == "Yes" ? (
                            <div class="mb-3">
                              {/* Comments */}
                              <div class="form-group">
                                <label class="input-label">Comentarios</label>
                                <Field type="text" name="comments"
                                  as="textarea" rows="6"
                                  class="form-control" />
                              </div>
                            </div>
                          ) : null
                        }

                        <div class="row mb-3">
                          <div class="col">
                            Requiere revisión de comité
                          </div>
                          <div class="col">

                            <div class="input-group input-group-md-down-break">
                              <div class="form-control">
                                <div class="custom-control custom-radio">
                                  <Field type="radio" class="custom-control-input"
                                    name="needsCommittee" id="needsCommitteeYes" value="Yes" />
                                  <label class="custom-control-label" for="needsCommitteeYes">Si</label>
                                </div>
                              </div>

                              <div class="form-control">
                                <div class="custom-control custom-radio">
                                  <Field type="radio" class="custom-control-input"
                                    name="needsCommittee" id="needsCommitteeNo" value="No" />
                                  <label class="custom-control-label" for="needsCommitteeNo">No</label>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>

                        {error ? <div class="alert alert-soft-danger">{error}</div> : null}
                        <button type="submit" class="btn btn-block btn-primary">ENVIAR REVISIÓN</button>
                      </Form>
                    )}
                  </Formik>
                )
            }


          </div>
        </div>
      </div>


    </div>
  </div>

);

ReviewView.propTypes = {
  review: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string
};

export default ReviewView;