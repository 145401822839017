import React from "react";
import { useGet } from "seed/api";
import { Loading } from "seed/helpers";
import View from "components/App.view";

function App() {
  const reqToken = useGet("/users/token", {code: "5a3c2a26-df13-4524-9df2-07d9a4d4a7d0"} )
  if (reqToken.loading) return <Loading />;
  if (reqToken.error) return "Token error";
  const {token, id} = reqToken.data;

  const id_session = sessionStorage.getItem("id");
  const token_session = sessionStorage.getItem("token");

  if (!id_session || !token_session) {
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("id", id);
  }


  return <View />;
}
App.propTypes = {};

export default App;
