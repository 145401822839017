import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

function getTitle(data) {
  return (data).title;
}

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.selected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};


const CommitteeView = ({
  review = {},
  document = {},
  onSubmit = () => { },
  error,
  participants = [],
  // optionSelected,
  // handleChange
}) => (
  <div class="card">
    {/* Header */}
    <div class="card-header">
      <h4 class="card-header-title">Comité</h4>
    </div>

    {/* Body */}
    <div class="card-body">
      <div class="row">
        <div class="col">

          <h4 class="pb-3">Datos de la opinión</h4>

          <div class="row mb-3">
            <div class="col-md-8">
              {getTitle(document.data)}
            </div>
            <div class="col-md-4">
              <a href={document.file ? document.file.url :
                `https://vault.netvoyage.com/neWeb2/goid.aspx?id=${document.request.ndDocumentId}`}
                target="_blank" rel="noreferrer" class="btn btn-outline-primary">
                Abrir opinión
              </a>
            </div>
          </div>

          <hr
            class="my-6"
            style={{ "border-top": "1px dashed green" }}
          />
          <h4 class="pb-3">Datos de la revisión</h4>

          <table class="table">

            <tr>
              <td>Con comentarios</td>
              <td>{review.hasComments ? "Si" : "No"}</td>
            </tr>

            <tr>
              <td>Necesita comité</td>
              <td>{review.needsCommittee ? "Si" : "No"}</td>
            </tr>

            {review.hasComments ? (
              <tr>
                <td>Descripción de corrección</td>
                <td>{review.comments}</td>
              </tr>
            ) : null}

          </table>

          <hr
            class="my-6"
            style={{ "border-top": "1px dashed green" }}
          />
          <h4 class="pb-3">Datos del comité</h4>

          <Formik initialValues={{}} onSubmit={onSubmit}>
            {({ values, setFieldValue }) => (
              <Form>

                <div class="mb-3">
                  {/* Participants */}
                  <div class="form-group">
                    <label class="input-label">Participantes</label>

                    <Field as="select" name="participants" class="form-control" required>
                      <option value="">Seleccione un socio</option>
                      {participants.map((participant) => (
                        <option key={`participant_${participant.id}`} value={participant.id}>
                          {participant.firstName + " " + participant.lastName}
                        </option>
                      ))}
                    </Field>

                    {/* <span
                      class="d-inline-block"
                      data-toggle="popover"
                      data-trigger="focus"
                      data-content="Selecciona los partcipantes"
                      style={{width: "100%"}}
                    >
                      <ReactSelect
                        options={
                          participants.map((participant) => {return {
                            value: participant.id,
                            label: participant.firstName + " " + participant.lastName
                          }})
                        }
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option
                        }}
                        onChange={handleChange}
                        allowSelectAll={true}
                        value={optionSelected}
                      />
                    </span> */}
                  </div>

                  {/* Date */}
                  <div class="form-group">
                    <label class="input-label">Fecha</label>
                    <Field type="date" name="date" class="form-control" />
                  </div>

                  {/* Data */}
                  <div class="form-group">
                    <label class="input-label">Minuta</label>
                    <Field type="text" name="data"
                      as="textarea" rows="6"
                      class="form-control"
                      placeholder="Indicar comentarios y observaciones la reunión" />
                  </div>

                  {/* Decision */}
                  <div class="form-group">
                    <label class="input-label">Decisión</label>
                    <Field as="select" name="decision"
                      class="form-control"  >
                      <option value="">Selecciona una decisión</option>
                      <option value="REMOVE_LETTER">Retirar carta</option>
                      <option value="REPLACE">Sustituir</option>
                      <option value="COMPLEMENT">Complementar</option>
                      <option value="TALK_WITH_ISSUER">Platica con emisor</option>
                      <option value="NOTHING">No hacer nada</option>
                    </Field>
                  </div>

                </div>

                {error ? <div class="alert alert-soft-danger">{error}</div> : null}
                <button type="submit" class="btn btn-block btn-primary">REGISTRAR COMITÉ</button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>

  </div>
);

CommitteeView.propTypes = {
  review: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  participants: PropTypes.array.isRequired,
  // selectedParticipants: PropTypes.array.isRequired
};

export default CommitteeView;