import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import swal from "sweetalert";
import { useLocation } from "react-router";
import View from "components/form/v1/QaRequestV1.view";
import NowVerified from "components/form/NowVerified";
import { usePost, useGet } from "seed/api";
import { Loading } from "seed/helpers";
import { IS_PROD } from "settings";
import { FORM_SCHEMA, INITIAL_FORM } from "utils";
import NotFound from "components/utils/NotFound";

function QaRequestV1() {
  const date = new Date();
  const [callLoading, setCallLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [docTypeS, setDocTypeS] = useState({ value: "", hidden: false });
  const [queryPermS, setQueryPermS] = useState({ value: "", hidden: false });
  const [formatSignS, setFormatSignS] = useState({ value: "", hidden: false });
  const [formatEnS, setFormatEnS] = useState({ value: "", hidden: false });
  const [docIsS, setDocIsS] = useState({ value: "", hidden: false });
  const [codFiscalFederationS, setCodFiscalFederationS] = useState({
    value: "",
  });
  const [otherDateSendS, setOtherDateSendS] = useState({
    value: "",
    hidden: false,
  });
  const [notifyReportS, setNotifyReportS] = useState({
    value: "",
    hidden: false,
  });

  const [inputPartners, setInputPartners] = useState([]);
  const [inputAssociated, setInputAssociated] = useState([]);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const NdUserId = localStorage.getItem("NdUserId");
  const NdDocumentId = localStorage.getItem("NdDocumentId");

  const request = query.get("id");

  const reqUsers = useGet("/users");
  const reqMetadata = useGet(
    "/approvals/nd_metadata",
    { nd_user_id: NdUserId, nd_document_id: NdDocumentId }
  );

  const reqApproval = useGet(
    "/requests/" + request + "/get_approval",
    {},
    {
      onCompleted: (data) => {
        const { approval } = data;
        if (
          approval.associated != undefined &&
          approval.partners != undefined
        ) {
          const { associated, partners, comments } = approval;
          partners.splice(-1, 1);
          setInputAssociated(associated);
          setInputPartners(partners);
          setComment(comments);
        }
      }
    }
  );
  const onCompletedRequest = (message) => {
    if (message == "Error S/A") {
      swal({
        title: "No se puede procesar la petición",
        icon: "error",
        text: "Debe agregar al menos un socio o asociado.",
        buttons: {
          confirm: {
            text: "Ok",
            className: "swal-button swal-button--cancel btn-error",
          },
        },
      });
    } else {
      if (message == "Error") {
        swal({
          title: "No se puede procesar la petición",
          icon: "error",
          text: "La información no ha sido enviada.",
          buttons: {
            confirm: {
              text: "Ok",
              className: "swal-button swal-button--cancel btn-error",
            },
          },
        });
      } else {
        swal({
          title: "Contenido enviado",
          icon: "success",
          text: "La información fue enviada con éxito",
          buttons: {
            confirm: {
              text: "Ok",
              className: "swal-button swal-button--cancel btn-success",
            },
          },
        }).then((respuesta) => {
          if (respuesta) {
            window.close();
          }
        });
      }
    }
  };

  const [callForm, reqForm] = usePost("/approvals/create_approval", {
    onCompleted: (data) => {
      setCallLoading(false);
      const { message } = data;
      onCompletedRequest(message);
    }
  });

  const onSubmit = (values) => {
    values.queryPerm =
      queryPermS.value == "" ? values.queryPerm : queryPermS.value;
    values.documentType =
      docTypeS.value == "" ? values.documentType : docTypeS.value;
    values.formatSignCheck =
      formatSignS.value == "" ? values.formatSignCheck : formatSignS.value;
    values.formatEn = formatEnS.value == "" ? values.formatEn : formatEnS.value;
    values.notifyReport =
      notifyReportS.value == "" ? values.notifyReport : notifyReportS.value;
    values.otherDate =
      otherDateSendS.value == "" ? values.otherDate : otherDateSendS.value;
    values.docIs = docIsS.value == "" ? values.docIs : docIsS.value;
    values.codFiscalFederation =
      codFiscalFederationS.value == ""
        ? values.codFiscalFederation
        : codFiscalFederationS.value;

    swal({
      title: "Enviar a Firmar",
      icon: "warning",
      text: "Acepto el contenido de la hoja de control de calidad",
      buttons: {
        confirm: {
          text: "Aceptar",
          className: "swal-button swal-button--cancel btn-success",
        },
        cancel: "Cancelar",
      },
    }).then((respuesta) => {
      const partners = [...inputPartners, { sent: false }];
      if (respuesta) {
        const dataForm = {
          body: values,
          status: "SENT",
          user_id: NdUserId,
          request_id: request,
          associated: inputAssociated,
          partners: partners,
          comments: comment,
        };
        callForm(dataForm);
        setCallLoading(true);
      }
    });
  };

  const addPartner = () => {
    setInputPartners([
      ...inputPartners,
      {
        email: "",
        approval: false,
        date_send:
          date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear(),
      },
    ]);
  };

  const setAssociated = (associated) => {
    setInputAssociated(associated);
  };

  const removePartner = (index) => {
    const values = [...inputPartners];
    values.splice(-1, 1);
    setInputPartners(values);
  };

  const addAssociated = () => {
    setInputAssociated([
      ...inputAssociated,
      {
        email: "",
        approval: false,
        date_send:
          date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear(),
      },
    ]);
  };

  const removeAssociated = (index) => {
    const values = [...inputAssociated];
    values.splice(-1, 1);
    setInputAssociated(values);
  };

  const onChangePartners = (index, event) => {
    const values = [...inputPartners];
    values[index][event.target.name] = event.target.value;
    setInputPartners(values);
  };

  const onChangeAssociated = (index, event) => {
    const values = [...inputAssociated];
    values[index][event.target.name] = event.target.value;
    setInputAssociated(values);
  };

  if (
    reqUsers.loading ||
    reqApproval.loading ||
    reqMetadata.loading ||
    callLoading
  )
    return <Loading />;
  if (reqUsers.error) return "error";
  if (request == "") return <NotFound />;

  const ndMetadata = reqMetadata.data;
  const dataPartnersAssociated = reqUsers.data;
  const approvalData = reqApproval.data;
  const approval = approvalData.approval;

  const getMetadataValue = (metadata, id) => {
    if (metadata.customAttributes == null) return "";
    let attrs = metadata.customAttributes;
    let ele = attrs.filter((attr) => attr.id == id);
    if (ele.length == 0) return "";
    return ele[0].description != null ? ele[0].description : ele[0].value;
  };

  let initialForm = Object.assign({}, INITIAL_FORM);
  /* TODO UPDATE PRODUCTION KEYS
  initialForm.nameClient = getMetadataValue(ndMetadata, 16);
  initialForm.emittingArea = getMetadataValue(ndMetadata, 13);
  initialForm.documentType = getMetadataValue(ndMetadata, 14);
  initialForm.description = getMetadataValue(ndMetadata, 7);
  */
  if (!approvalData.status || approval.status == "REJECTED") {
    return (
      <View
        status={approval.status == "REJECTED" ? approval.status : ""}
        formData={approval.status == "REJECTED" ? approval.body : initialForm}
        comments={
          approval.status == "REJECTED" ? approval.comments.split(":")[0] : ""
        }
        docTypeS={docTypeS}
        queryPermS={queryPermS}
        formatSignS={formatSignS}
        formatEnS={formatEnS}
        notifyReportS={notifyReportS}
        otherDateSendS={otherDateSendS}
        docIsS={docIsS}
        setDocIsS={setDocIsS}
        setOtherDateSendS={setOtherDateSendS}
        setDocTypeS={setDocTypeS}
        setQueryPermS={setQueryPermS}
        setFormatSignS={setFormatSignS}
        setFormatEnS={setFormatEnS}
        setNotifyReportS={setNotifyReportS}
        onSubmit={onSubmit}
        addPartner={addPartner}
        removePartner={removePartner}
        addAssociated={addAssociated}
        removeAssociated={removeAssociated}
        inputPartners={inputPartners}
        inputAssociated={inputAssociated}
        onChangePartners={onChangePartners}
        onChangeAssociated={onChangeAssociated}
        FormSchema={FORM_SCHEMA}
        dataPartnersAssociated={dataPartnersAssociated}
        codFiscalFederationS={codFiscalFederationS}
        setCodFiscalFederationS={setCodFiscalFederationS}
      />
    );
  } else {
    if (approval.status == "SIGNED") {
      return <NowVerified status={"SIGNED"} />;
    } else {
      return <NowVerified status={""} />;
    }
  }
}

QaRequestV1.propTypes = {};

export default QaRequestV1;