import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";

const ReviewerView = ({ partners = [], onSubmit = () => { }, error, document = {}, edit = false }) => (
  <div class="card">
    {/* Header */}
    <div class="card-header">
      <h4 class="card-header-title">REVISOR</h4>
    </div>

    {/* Body */}
    <div class="card-body">
      <div class="row">
        <div class="col">
          <Formik initialValues={document} onSubmit={onSubmit}>
            {({ values, setFieldValue }) => (
              <Form>
                <div class="mb-3">
                  {/* Partner */}
                  <div class="form-group">
                    <label class="input-label">Listado de Socios</label>
                    <Field as="select" name="reviewer" class="form-control">
                      <option value="">Seleccione un socio</option>
                      {partners.map((partner) => (
                        <option key={`partner_${partner.id}`} value={partner.id}>
                          {partner.firstName + " " + partner.lastName}
                        </option>
                      ))}
                    </Field>
                  </div>

                  {/* Deadline */}
                  <div class="form-group">
                    <label class="input-label">Fecha Límite</label>
                    <Field type="date" name="deadline" class="form-control" />
                  </div>

                </div>
                {error ? <div class="alert alert-soft-danger">{error}</div> : null}

                {edit ? (
                  <>
                    <button type="button" class="btn btn-block btn-primary mb-3"
                      onClick={() => onSubmit(values, false)}
                    >MODIFICAR</button>
                    <button
                      onClick={() => onSubmit(values, true)}
                      type="button" class="btn btn-block btn-warning">
                        <i class="far fa-envelope mr-3"></i>INICIAR REVISIÓN
                    </button>
                  </>
                ) : (
                  <button type="submit" class="btn btn-block btn-primary">ASIGNAR</button>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  </div>
);

ReviewerView.propTypes = {
  partners: PropTypes.array.isRequired,
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  edit: PropTypes.bool,
  document: PropTypes.object,
  buttonText: PropTypes.string
};

export default ReviewerView;