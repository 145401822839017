import React from "react";
import PropTypes from "prop-types";
import { Link, BrowserRouter } from "react-router-dom";
import moment from "moment";
import "moment/locale/es-mx";

const HistoryView = ({ document, actions = [] }) => (
  <BrowserRouter basename={`/home/${document.id}/history`}>
    <div class="card mb-3 mb-lg-5">
      <div class="card-header">
        <h5 class="card-header-title">DETALLE DE REVISIÓN</h5>
        
        <a href={ document.file ? document.file.url : 
          `https://vault.netvoyage.com/neWeb2/goid.aspx?id=${document.request.ndDocumentId}`} 
          target="_blank" rel="noreferrer" class="btn btn-outline-primary">
          Abrir opinión
        </a>
      </div>

      <div class="card-body">
        <ul class="step steo-icon-xs">

          {actions.map((action) => (
            <li class="step-item" key={`item_${action.date}`}>
              <div class="step-content-wrapper">
                <span class="step-icon step-icon-pseudo step-icon-soft-dark"></span>
                <div class="step-content">
                  <h5 class="mb-1">
                    <a class="text-dark">
                      {action.text}
                    </a>
                  </h5>
                  {!action.close ? (
                    <>
                      <p>
                        <a data-toggle="collapse" href={`#details${action.id}`} 
                          role="button" aria-expanded="false" aria-controls={`details${action.id}`}>
                          Ver detalles
                        </a>
                      </p>
                      <div class="collapse" id={`details${action.id}`}>
                        <div class="card card-body shadow-none">
                          {action.details}
                        </div>
                      </div>
                    </>
                  ) : null}
                  <h7 class="text-muted text-uppercase">
                    {moment(action.date).format("DD/MM/YYYY")}
                  </h7>
                </div>
              </div>
            </li>
          ))}

        </ul>
      </div>

      <div class="card-footer">
      </div>
    </div>
  </BrowserRouter>
);

HistoryView.propTypes = {
  document: PropTypes.object,
  actions: PropTypes.array
};

export default HistoryView;