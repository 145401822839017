import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { usePost } from "seed/api";
import { useLocation } from "react-router";
import { IS_PROD } from "settings";

function Index({ history }) {
  let { search } = useLocation();
  let query = new URLSearchParams(search);
  const ndDocumentId = query.get("DocumentId");
  const ndEnvelopeId = query.get("EnvelopeId");
  const ndVerNo = query.get("VerNo");
  const ndUserId = query.get("UserId");
  localStorage.setItem("NdDocumentId", ndDocumentId);
  localStorage.setItem("NdEnvelopeId", ndEnvelopeId);
  localStorage.setItem("NdVerNo", ndVerNo);
  localStorage.setItem("NdUserId", ndUserId);
  const [callAuth, reqAuth] = usePost("/users/" + ndUserId + "/verify", {
    onCompleted: (data) => {
      const { verify } = data;
      const redirect_url = IS_PROD
        ? "https://crono.chevez.com/nd"
        : "https://datagrid.mx/actions/redirect_3003/nd";
      const client_id = IS_PROD ? "AP-0QX9591M" : "AP-23HTM7AP"
      if (verify) history.replace("/PageFirm");
      if (!verify)
        window.location =
          `https://vault.netvoyage.com/neWeb2/OAuth.aspx?client_id=${client_id}&scope=full&response_type=code&redirect_uri=${encodeURI(redirect_url)}`;

    }
  });

  useEffect(() => {
    callAuth();
  }, []);

  return <div></div>;
}

Index.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Index;
