import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery, useDetail } from "seed/gql";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import swal from "sweetalert";
import View from "components/cc/correction/Committee.view";

function Committee({ onCompleted = () => null, onError = () => null, reqDocuments, documentId }) {
  
  // const [optionSelected, setOptionSelected] = useState(null)
  const [error, setError] = useState(null);

  const [callSaveCommittee, reqSaveCommittee] = usePost("/documents/create_committee", {
    onCompleted: () => {
      swal({
        title: "Comité creado exitosamente",
        icon: "success",
        text: "El comité se ha registrado exitosamente",
        buttons: {
          confirm: {
            text: "Ok",
            className: "swal-button swal-button--cancel btn-success",
          },
        },
      }).then((respuesta) => {
        if (respuesta) {
          window.location.href = "/home";
        }
      });
    },
    onError: (data) => {
      console.log(data);
      if (data.status == 401) {
        setError("El documento no esta revisado, por favor reinicie la página");
      } else {
        setError("Error interno del servidor, revise sus entradas e intente de nuevo");
      }
    }
  });

  const reqDocument = useDetail(`{
    document {
      data
      createdAt
      updatedAt
      status
      request {
        ndDocumentId
      }
      file {
        url
      }
    }
  }`, documentId, {
    onError: (error) => {
      console.log(error);
      window.location.href = "/home";
    }
  })

  const reqReviews = useQuery(`{
    reviews {
      createdAt
      updatedAt
      hasComments
      needsCommittee
      comments
      committees {
        id
      }
      dateReviewed
      dateApproved
    }
  }`, `document.id = ${documentId}`, { 
    onError: (error) => {
      console.log(error);
      window.location.href = "/home";
    }, 
    orderBy: "date_reviewed" 
  })

  const reqUsers = useQuery(`{
    users {
      email
      lastName
      firstName
    }
  }`, "", { orderBy: "first_name" })

  if (reqDocument.loading || reqReviews.loading || reqUsers.loading) return <Loading />;
  if (reqDocument.error || reqReviews.error || reqUsers.error) return "Error";

  const { reviews = [] } = reqReviews.data;
  const { document = {} } = reqDocument.data;
  const { users = [] } = reqUsers.data;

  if (document.status != "NEEDS_COMMITTEE")
    window.location.href = "/home";

  let lastReview = {};
  if (reviews.length > 0) lastReview = reviews[0];
  else window.location.href = "/home";

  const onSubmit = (values) => {
    const date = values["date"];
    const data = values["data"];
    // const participants = optionSelected;
    const participants = values["participants"];
    const decision = values["decision"];

    if (!date || !data || !participants || !decision) {
      setError("Debe llenar todos los campos");
      return;
    }

    // if (participants.length == 0) {
    //   setError("Debe seleccionar al menos a un participante")
    //   return;
    // }

    callSaveCommittee({
      reviewId: lastReview.id,
      date: date,
      data: data,
      participants: [participants],
      // participants: participants,
      decision: decision
    })

  }

  // const handleChange = (selected) => setOptionSelected(selected)

  return (
    <View
      review={lastReview}
      document={document}
      onSubmit={onSubmit}
      error={error}
      participants={users}
      // optionSelected={optionSelected}
      // handleChange={handleChange}
    />
  );
}

Committee.propTypes = {
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
  reqDocuments: PropTypes.object,
  documentId: PropTypes.string,
  props: PropTypes.object,
  match: PropTypes.object
};

export default Committee;