import React from "react";
import PropTypes from "prop-types";
import View from "components/partner/Empty.view";

function Empty() {
  return <View />;
}

Empty.propTypes = {};

export default Empty;