import RequestDetail from "components/requests/RequestDetail";
import RequestList from "components/requests/RequestList";
import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import { ModalRoute } from "seed/helpers";

const Request = () => (
  <BrowserRouter basename="/administrador">
    <main
      id="content"
      role="main"
      class="main"
      style={{ "padding-left": "2.25rem" }}
    >
      <div
        class="position-fixed top-0 right-0 left-0 bg-img-hero"
        style={{
          height: "32rem",
          backgroundImage: "url(/theme/svg/components/abstract-bg-4.svg)",
        }}
      >
        <figure class="position-absolute right-0 bottom-0 left-0">
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1921 273"
          >
            <polygon fill="#00454d" points="0,273 1921,273 1921,0 " />
          </svg>
        </figure>
      </div>

      <div class="container py-5 py-sm-7">
        <a class="d-flex justify-content-center mb-5" href="index.html">
          <h1
            class="mt-2 display-4"
            style={{
              position: "absolute",
              "z-index": "3",
              color: "white",
              "margin-right": "22.5rem",
            }}
          >
            Hoja Crono
          </h1>
          <img
            class="z-index-2"
            src="/theme/svg/logos/logoCrz.png"
            alt="Logo"
            style={{ width: "8rem" }}
          />
        </a>
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div class="card card-lg mb-5">
              <div class="card-body">
                <div class="mb-5">
                  <h1 class="display-5">Request</h1>
                </div>

                {/* List */}
                <RequestList />

                {/* Modals */}
                <ModalRoute path="/:requestId(\d+)" component={RequestDetail} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </BrowserRouter>
);

Request.propTypes = {};

export default Request;
