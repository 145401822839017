import React from "react";
import PropTypes from "prop-types";
import { useQuery, useDetail } from "seed/gql";
import { Loading } from "seed/helpers";
import { getDecisionName } from "components/utils/constants";
import moment from "moment";
import "moment/locale/es-mx";
import View from "components/cc/history/History.view";

function History(props) {

  const { documentId } = props.match.params;

  const reqDocument = useDetail(`{
    document {
      data
      createdAt
      updatedAt
      status
      emisor {
        email
        firstName
        lastName
      }
      request {
        ndDocumentId
        createdAt
        createdBy {
          email
          firstName
          lastName
        }
      }
      reviewer {
        email
        firstName
        lastName
      }
      file {
        url
      }
    }
  }`, documentId, {
    onError: (error) => {
      console.log(error);
      window.location.href = "/home";
    }
  })

  const reqReviews = useQuery(`{
    reviews {
      createdAt
      updatedAt
      deadline
      hasComments
      needsCommittee
      comments
      commentsCorrection
      committees {
        date
        memorandum
        decision
        participants {
          email
          firstName
          lastName
        }
      }
      dateReviewed
      dateApproved
      isApproved
    }
  }`, `document.id = ${documentId}`, { 
    onError: (error) => {
      console.log(error);
      window.location.href = "/home";
    },
    orderBy: "date_reviewed" 
  });

  if (reqDocument.loading || reqReviews.loading) return <Loading />;
  if (reqDocument.error || reqReviews.error) return "Error";

  const { reviews = [] } = reqReviews.data;
  const { document = {} } = reqDocument.data;

  let i = 0;
  const actions = [];
  actions.push({
    id: i,
    close: false,
    text: "Se envió a aprobar hoja crono",
    date: document.createdAt,
    details: (
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Envío de hoja crono</h4>
          <p class="card-text">
            El usuario {document.request?.createdBy?.firstName + " " + document.request?.createdBy?.lastName + " "}
            envió a aprobar la hoja crono el {moment(document.request?.createdAt).format("DD/MM/YYYY")}
          </p>
        </div>
      </div>
    )
  });
  i++;

  actions.push({
    id: i,
    close: false,
    text: "Se aprobó hoja crono",
    date: document.createdAt,
    details: (
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Aprobación de hoja crono</h4>
          <p class="card-text">
            Se registró la hoja crono como aprobada el {moment(document.createdAt).format("DD/MM/YYYY")}
          </p>
        </div>
      </div>
    )
  });
  i++;

  reviews.forEach(review => {

    actions.push({
      id: i,
      close: false,
      text: "Se asignó revisor a hoja crono y está en espera de revisión",
      date: review.createdAt,
      details: (
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Asignación de hoja crono</h4>
            <p class="card-text">
              La hoja cronó se asignó al revisor {" "}
              {document.reviewer.firstName + " " + document.reviewer.lastName + " "}
              y está en espera de revisión con un plazo máximo hasta {" "}
              {moment(review.deadline).format("DD/MM/YYYY")}
            </p>
          </div>
        </div>
      )
    });
    i++;

    if (review.dateReviewed) {
      if (review.hasComments && !review.needsCommittee) {
        actions.push({
          id: i,
          close: false,
          text: "Se completó revisión con comentarios",
          date: review.dateReviewed,
          details: (
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Se completó revisión</h4>
                <p class="card-text">
                  La hoja cronó fue revisada, tiene comentarios que necesitan corregirse. <br />
                  Descripción de corrección: <b>{'"'}{review.comments}{'"'}</b>
                </p>
              </div>
            </div>
          )
        });
        i++;
      } else if (review.needsCommittee) {
        actions.push({
          id: i,
          close: false,
          text: "Se completó revisión, requiere comité",
          date: review.dateReviewed,
          details: (
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Se completó revisión</h4>
                <p class="card-text">
                  La hoja cronó fue revisada, tiene comentarios y necesita comité. <br />
                  Descripción de corrección: <b>{'"'}{review.comments}{'"'}</b>
                </p>
              </div>
            </div>
          )
        });
        i++;
      }
    }

    if (review.dateApproved) {
      if (review.commentsCorrection && review.committees.length == 0) {
        actions.push({
          id: i,
          close: false,
          text: "Se registraron comentarios de corrección",
          date: review.dateApproved,
          details: (
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Comentarios de corrección</h4>
                <p class="card-text">
                  Un administrador comprobó la revisión y registró comentarios. <br />
                  Comentarios: <b>{'"'}{review.commentsCorrection}{'"'}</b>
                </p>
              </div>
            </div>
          )
        });
        i++;
      } else if (review.committees.length > 0) {
        actions.push({
          id: i,
          close: false,
          text: "Se registró el comité",
          date: review.dateApproved,
          details: (
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Resumen del comité</h4>
                <p class="card-text">
                  <ul class="list-checked list-checked-primary">
                    <li class="list-checked-item">
                      Fecha: <b>{moment(review.committees[0].date).format("DD/MM/YYYY")}</b>
                    </li>
                    <li class="list-checked-item">
                      Decisión tomada: <b>{getDecisionName(review.committees[0].decision)}</b>
                    </li>
                    <li class="list-checked-item">
                      Participantes:
                      <ul class="list-checked list-checked-soft-bg-primary">
                        {review.committees[0].participants.map((participant) => (
                          <li key={`participant_${participant.id}`} class="list-checked-item">
                            {participant.firstName + " " + participant.lastName}
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li class="list-checked-item">Minuta: <b>{review.committees[0].memorandum}</b></li>
                  </ul>
                </p>
              </div>
            </div>
          )
        });
        i++;
      }
    }
  });

  if (document.status == "REVIEWED" ||
    document.status == "REVIEWED_WITH_COMMENTS" || document.status == "REVIEWD_WITH_COMMITTEE") {

    actions.push({
      id: i,
      close: true,
      text: "Cierre del documento",
      date: document.updatedAt
    });

  }


  return <View document={document} actions={actions} />;
}

History.propTypes = {
  props: PropTypes.object,
  match: PropTypes.object
};

export default History;