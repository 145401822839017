import React, { useState, useEffect } from "react";
import { usePost } from "seed/api";
import PropTypes from "prop-types";
import View from "components/Home.view";

function Home() {
  const [isAuth, setIsAuth] = useState(false)

  const [callGeneralReport, reqGeneralReport] = usePost("/approvals/create_report_general_hc", {
    onCompleted: (data) => window.location.href = data,
    onError: () => alert("Ha ocurrido un error al generar el reporte")
  })

  const [callDetailReport, reqDetailReport] = usePost("/approvals/create_report_detail_hc", {
    onCompleted: (data) => window.location.href = data,
    onError: () => alert("Ha ocurrido un error al generar el reporte")
  })

  const [callUsersReport, reqUsersReport] = usePost("/approvals/create_report_users_hc", {
    onCompleted: (data) => window.location.href = data,
    onError: () => alert("Ha ocurrido un error al generar el reporte")
  })

  const [callDocumentsReport, reqDocumentsReport] = usePost("/documents/create_report", {
    onCompleted: (data) => window.location.href = data,
    onError: () => alert("Ha ocurrido un error al generar el reporte")
  })


  const onClickGeneralReport = () => {
    callGeneralReport()
  }

  const onClickDetailReport = () => {
    callDetailReport()
  }

  const onClickUsersReport = () => {
    callUsersReport()
  }

  const onClickDocumentsReport = () => {
    callDocumentsReport();
  }

  useEffect(() => {
    if (localStorage.getItem("id") != null) { //Preload data from localStorage
      sessionStorage.setItem("token", localStorage.getItem("token"));
      sessionStorage.setItem("id", localStorage.getItem("id"));
      sessionStorage.setItem("role", localStorage.getItem("role"));
    }
    if (sessionStorage.getItem("id") != null && sessionStorage.getItem("isCC") == "true" )
      setIsAuth(true);
    else window.location.replace("/cc_login");
  }, []);

  return <View 
      onClickGeneralReport={onClickGeneralReport} 
      onClickDetailReport={onClickDetailReport}
      onClickUsersReport={onClickUsersReport}
      onClickDocumentsReport={onClickDocumentsReport}
    />;
}

Home.propTypes = {
  trash: PropTypes.bool
};

export default Home;