import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { usePagination } from "seed/gql";
import { usePost, useGetCall } from "seed/api"
import { Loading } from "seed/helpers";
import swal from "sweetalert";
import { getDocumentsFilter } from "components/utils/filters";
import View from "components/cc/DocumentList.view";


function DocumentList({
  trash = false,
  filters = {},
  pageNum = 0,
  pageSize = 0,
  setPageNum = () => { },
  onClickPage = () => { },
  onChangeFilter = () => { },
}) {

  const [documents, setDocuments] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [orderFolio, setOrderFolio] = useState(null);
  const [orderDocumentDate, setOrderDocumentDate] = useState(null);

  const [callDocuments, reqDocuments] = useGetCall("/documents/get_documents", {
    page_num: pageNum,
    page_size: pageSize,
    filters: JSON.stringify(filters),
    sort: JSON.stringify({
      "folio": orderFolio,
      "document_date": orderDocumentDate
    })
  }, {
    onCompleted: (response) => {
      setTotalPages(response.total_pages)
      setDocuments(response.data)
    },
    onError: (err) => {
      console.error(err);
    }
  })

  const [callDelete, reqDelete] = usePost("/documents/delete_document", {
    onCompleted: () => {
      swal({
        title: "Documento eliminado exitosamente",
        icon: "success",
        text: "El documento ha sido eliminado exitosamente",
        buttons: {
          confirm: {
            text: "Ok",
            className: "swal-button swal-button--cancel btn-success",
          },
        },
      }).then((respuesta) => {
        if (respuesta) {
          window.location.href = "/home";
        }
      });
    }, onError: (data) => {
      alert("Ha ocurrido un error, por favor intentelo mas tarde");
      console.log(data);
    }
  });

  const [callRestore, reqRestore] = usePost("/documents/restore", {
    onCompleted: () => {
      swal({
        title: "Documento restaurado exitosamente",
        icon: "success",
        text: "El documento ha sido restaurado exitosamente",
        buttons: {
          confirm: {
            text: "Ok",
            className: "swal-button swal-button--cancel btn-success",
          },
        },
      }).then((respuesta) => {
        if (respuesta) {
          window.location.href = "/home";
        }
      });
    }, onError: (error) => {
      alert("Ha ocurrido un error, por favor intentelo mas tarde");
      console.log(error);
    }
  });

  const [callResend, reqResend] = usePost("/documents/resend_document", {
    onCompleted: () => {
      swal({
        title: "Asignación reenviada al socio exitosamente",
        icon: "success",
        text: "El link para hacer la revisión del documento ha sido reenviada exitosamente al socio.",
        buttons: {
          confirm: {
            text: "Ok",
            className: "swal-button swal-button--cancel btn-success",
          },
        },
      }).then((respuesta) => {
        if (respuesta) {
          window.location.href = "/home";
        }
      });
    }, onError: (data) => {
      alert("Ha ocurrido un error, por favor intentelo mas tarde");
      console.log(data);
    }
  })

  useEffect(() => {
    callDocuments();
  }, [pageNum, pageSize, filters, orderFolio, orderDocumentDate])

  useEffect(() => {
    setPageNum(1);
  }, [pageSize, filters])

  if (reqDocuments.loading) return <Loading />;
  if (reqDocuments.error) return "Error";

  const getLastReview = (id) => {
    let reviews = documents.find((document) => document.id == id).reviews;
    if (reviews.length == 0) return null

    reviews.sort((a, b) => {
      if (a.updatedAt < b.updatedAt) return -1
      if (a.updatedAt > b.updatedAt) return 1
      return 0;
    })
    return reviews[0];
  }

  const onClickDelete = (id) => {
    swal({
      title: "Eliminar documento",
      icon: "warning",
      text: "¿Esta seguro que desea eliminar el documento, este se enviará a la papelera de reciclaje?",
      buttons: {
        confirm: {
          text: "Aceptar",
          className: "swal-button swal-button--cancel btn-success",
        },
        cancel: "Cancelar",
      },
    }).then((response) => {
      if (response)
        callDelete({ documentId: id })
    });
  }

  const onClickResend = (id) => {
    swal({
      title: "Reenviar asignación",
      icon: "warning",
      text: "¿Esta seguro que desea reenviar la asignación del documento?",
      buttons: {
        confirm: {
          text: "Aceptar",
          className: "swal-button swal-button--cancel btn-success",
        },
        cancel: "Cancelar",
      },
    }).then((response) => {
      if (response)
        callResend({ documentId: id })
    });
  }

  const onRestoreDocument = (id) => {
    swal({
      title: "Restaurar documento",
      icon: "warning",
      text: "¿Esta seguro que desea restaurar el documento?",
      buttons: {
        confirm: {
          text: "Aceptar",
          className: "swal-button swal-button--cancel btn-success",
        },
        cancel: "Cancelar",
      },
    }).then((response) => {
      if (response) {
        callRestore({ documentId: id })
      }
    });
  }

  return (
    <View
      trash={trash}
      documents={documents}
      orderFolio={orderFolio}
      setOrderFolio={setOrderFolio}
      orderDocumentDate={orderDocumentDate}
      setOrderDocumentDate={setOrderDocumentDate}
      totalPages={totalPages}
      pageNum={pageNum}
      pageSize={pageSize}
      onClickPage={onClickPage}
      onClickDelete={onClickDelete}
      onClickResend={onClickResend}
      reqDocuments={reqDocuments}
      onChangeFilter={onChangeFilter}
      getLastReview={getLastReview}
      onRestoreDocument={onRestoreDocument}
    />
  );
}

DocumentList.propTypes = {
  trash: PropTypes.bool,
  filters: PropTypes.object,
  pageNum: PropTypes.number,
  pageSize: PropTypes.number,
  onClickPage: PropTypes.func,
  onChangeFilter: PropTypes.func,
};

export default DocumentList;