import React from "react";
import PropTypes from "prop-types";
import { useDetail, useQuery } from "seed/gql";
import { useLocation } from 'react-router-dom';
import NotFound from "components/utils/NotFound";
import { Loading } from "seed/helpers"
import View from "components/partner/Main.view";

function Main() {
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');
  const id = query.get('id');
  const reqReview = useDetail(`{
    review {
      token
      deadline
      dateReviewed
      isDelayed
      document {
        data
        status
        request {
          ndDocumentId
        }
        file {
          url
        }
        request {
          id
        }
      }
    }
  }`, id);

  if (reqReview.loading) return <Loading />;
  if (reqReview.error) return <NotFound />;

  const { review = {} } = reqReview.data;

  if (review.token != token) 
    window.location.href = "/";

  if (review.dateReviewed) 
    return <View reviewed={true}/>

  return <View review={review} id={id} token={token}/>;
}

Main.propTypes = {};

export default Main;