import React from "react";
import PropTypes from "prop-types";
import View from "components/requests/Request.view";

function Request() {
  return <View />;
}

Request.propTypes = {};

export default Request;