import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const RequestDetail = ({ request }) =>
  <div class="card">

    {/* Header */}
    <div class="profile-cover">
      <div class="profile-cover-img-wrapper">
        <img id="profileCoverImg" class="profile-cover-img"
          src="/theme/svg/components/abstract-bg-4.svg" alt="Cover" />
      </div>
    </div>
    <label class="avatar avatar-xxl avatar-circle avatar-border-lg profile-cover-avatar">
      <img id="avatarImg" class="avatar-img"
        src="/theme/svg/components/placeholder-img-format.svg" alt="Icon" style={{ width: "100%" }} />
    </label>

    {/* Body */}
    <div class="card-body">
      <div class="row">
        <div class="col">
          <label>Nombre:</label>
          <h2>{request["general"]["name"]}</h2>
          <div class="form-group">
            <label>Contenido metadata</label>
            <textarea class="form-control" rows="12" style={{ fontFamily: "Courier New" }} disabled>
              {JSON.stringify(request, null, "    ")}
            </textarea>
          </div>
        </div>
      </div>
    </div>

  </div>;

RequestDetail.propTypes = {
  request: PropTypes.object.isRequired,
};

export default RequestDetail;