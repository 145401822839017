import React from "react";
import PropTypes from "prop-types";
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const HelpMessage = (props) => (
    <OverlayTrigger key="bottom" placement="bottom"
        overlay={
            <Popover id="popover-positioned-right">
                <Popover.Body className="p-3"
                    style={{ backgroundColor: "#ddd", color: "black", borderRadius: "10px" }}>
                    {props?.message ?? ""}
                </Popover.Body>
            </Popover>
        }
    >
        {props.children}
    </OverlayTrigger>
)

HelpMessage.propTypes = {
};

export default HelpMessage;