import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "seed/gql";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers";
import swal from "sweetalert";
import View from "components/cc/Trash.view";

function Trash() {

  const pageSize = 15;
  const [pageNum, setPageNum] = useState(1);
  const [filters, setFilters] = useState({
    client: "all",
    status: "all",
    delayed: false,
    start_deadline: "all",
    end_deadline: "all",
    start_sync: "all",
    end_sync: "all",
    partner: "all",
    trash: true
  });

  const reqPartners = useQuery(`{
    users {
      firstName
      lastName
      email
    }
  }`, `status=PARTNER`, { orderBy: "first_name" });

  if (reqPartners.loading) return <Loading />;
  if (reqPartners.error) return "Error";

  const { users = [] } = reqPartners.data;

  const onClickPage = (pageNum) => setPageNum(pageNum);

  const onChangeFilter = (event, type) => {
    let newFilters = Object.assign({}, filters);
    if (type == "delayed") {
      newFilters[type] = !newFilters[type]
    } else {
      newFilters[type] = event.target.value;
    }
    setFilters(newFilters);
  }


  return (
    <View
      partners={users}
      filters={filters}
      pageNum={pageNum}
      setPageNum={setPageNum}
      pageSize={pageSize}
      onClickPage={onClickPage}
      onChangeFilter={onChangeFilter}
    />
  );


}

Trash.propTypes = {};

export default Trash;