import React from "react";
import PropTypes from "prop-types";

const NoVerify = ({ status }) =>
  <main id="content" role="main" class="main">
    <div
      class="position-fixed top-0 right-0 left-0 bg-img-hero"
      style={{
        height: "32rem",
        backgroundImage: "url(/theme/svg/components/abstract-bg-4.svg)",
      }}
    >
      <figure class="position-absolute right-0 bottom-0 left-0">
        <svg
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1921 273"
        >
          <polygon fill="#00454d" points="0,273 1921,273 1921,0 " />
        </svg>
      </figure>
    </div>

    <div class="container py-5 py-sm-7">
      <a class="d-flex justify-content-center mb-5" href="index.html">
        <img
          class="z-index-2"
          src="/theme/svg/logos/logoCrz.png"
          alt="Logo"
          style={{ width: "8rem" }}
        />
      </a>

      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card card-lg mb-5">
            <div class="card-body">
              {status == "false" ? (
                <div class="row">
                  <div class="col-5">
                    <img
                      class="avatar-xxl mb-3 ml-4"
                      src="/theme/svg/logos/warning.svg"
                      alt="warning"
                    />
                  </div>
                  <div class="col-7 mt-3">
                    <h1 class="card-title">No puedes acceder a la aprobación seleccionada</h1>
                    <p class="card-text">
                      Solicita que se te agregue a la aprobación o dirigite a netDocuments.
                    </p>
                  </div>
                </div>
              ) : (
                <div class="row">

                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>;

NoVerify.propTypes = {
  status: PropTypes.string,
};

export default NoVerify;